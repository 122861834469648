import React from 'react'

const LocalPay = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg8"
    version="1.1"
    height="5.8240085mm"
    width="5.8208332mm">
        <path fill="#A2A9B0" d="m 9,19.941 c -3.95,-0.49 -7,-3.85 -7,-7.93 0,-0.62 0.08,-1.22 0.21,-1.79 L 7,15.011 v 1 c 0,1.104 0.896,2 2,2 z m 6.9,-2.54 c -0.26,-0.8 -1.01,-1.39 -1.9,-1.39 h -1 v -2.189 c -1.115,-0.38 -2.116,-1.002 -2.937,-1.81 H 6 v -2 h 2 c 0.196,0 0.369,-0.072 0.523,-0.17 C 8.038,8.832 7.759,7.702 7.759,6.505 7.759,4.846 8.286,3.313 9.175,2.053 4.039,2.473 0,6.767 0,12.011 c 0,2.652 1.054,5.196 2.929,7.071 1.875,1.875 4.419,2.93 7.07,2.93 2.653,0 5.197,-1.055 7.072,-2.93 1.685,-1.685 2.699,-3.91 2.889,-6.267 -0.663,0.47 -1.4,0.842 -2.195,1.086 -0.325,1.327 -0.973,2.525 -1.865,3.5 z" />
        <path fill="#A2A9B0" d="M 19,5.136 H 17.25 V 4.792 c 0,-0.568 -0.59,-1.03 -1.313,-1.03 h -0.875 c -0.723,0 -1.312,0.462 -1.312,1.03 0,0.57 0.589,1.031 1.312,1.031 h 0.875 c 1.689,0 3.063,1.08 3.063,2.407 0,1.208 -1.145,2.202 -2.625,2.371 v 1.41 h -1.75 v -1.41 C 13.145,10.432 12,9.438 12,8.231 V 7.886 h 1.75 V 8.23 c 0,0.57 0.589,1.031 1.312,1.031 h 0.875 c 0.724,0 1.313,-0.462 1.313,-1.03 0,-0.57 -0.59,-1.032 -1.313,-1.032 H 15.062 C 13.374,7.2 12,6.12 12,4.792 12,3.584 13.145,2.59 14.625,2.422 v -1.41 h 1.75 v 1.41 C 17.855,2.59 19,3.583 19,4.791 V 5.135 Z M 15.495,0 C 11.9,0 8.989,2.912 8.989,6.505 c 0,3.593 2.912,6.506 6.506,6.506 C 19.087,13.011 22,10.098 22,6.505 22,2.912 19.087,0 15.495,0 Z" />
    </svg>
    )
}

export default LocalPay;
import axios from "axios";
import { devbugData } from "../libs/methods";

const tokenExpired = (message) => {
  if (message === "Unauthorized - Token not exists or expired")
    window.location.reload();
};

export async function getMyData(token, dispatch) {
  dispatch({ type: "SET_LOADING", loading: true });
  await axios
    .get(`${devbugData()}/me`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: "SET_MY_DATA", datos: res.data.message });

      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      tokenExpired(err.response.data);
    });
}

export async function updateMyData(token, affiliate_data, dispatch) {
  dispatch({ type: "SET_LOADING", loading: true });
  await axios
    .post(`${devbugData()}/management/updateprofile`, { token, affiliate_data })
    .then((res) => {
      if (res.data.status === "Accepted") {
        dispatch({ type: "SET_MY_DATA", datos: res.data.message });
      } else {
        tokenExpired(res.data.message);
      }
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
    });
}

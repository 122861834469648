import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//translate
import translate from "../../i18n/translate";

//components
import Logo from "../icons/logo";
import Logout from "../icons/logout";
import UserMovil from "../icons/userMovil";
import UserDesktop from "../icons/userDesktop";
import Menu from "../icons/menu";
import Close from "../icons/close";
//libs
import { getAuth } from "../../libs/cookies";
//style
import "./index.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      type: "member",
    };
  }
  componentDidMount() {
    const auth = getAuth();
    this.setState({
      username: auth.username,
      type: auth.type,
    });
  }

  backHome = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <React.Fragment>
        {this.props.showLinks && (
          <header className="header-container" id="header">
            {this.props.showMenu ? (
              <Menu showSidebar={this.props.showSidebar} />
            ) : (
              <span
                className="close-menu"
                onClick={(e) => this.props.showSidebar()}
              >
                <Close color="#fff" />
              </span>
            )}
            <Logo backHome={this.backHome} />
            <UserMovil showUser={this.props.showUser} />
            <div id="menu-user" className="bottons-content">
              <span onClick={(e) => this.props.logoutUser()}>
                <Logout color={this.props.showUserMenu ? "#fff" : "#A2A9B0"} />
                {translate("sidebar-close")}
              </span>
              <span className="noClick">
                <UserDesktop
                  color={this.props.showUserMenu ? "#fff" : "#A2A9B0"}
                />
                {this.state.username}{" "}
                {this.state.type === "admin" && <span>(admin)</span>}
              </span>
            </div>
          </header>
        )}
        {!this.props.showLinks && (
          <header className="header-login-container">
            {" "}
            <Logo backHome={this.backHome} />{" "}
          </header>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Header);

import React, { useEffect, useState } from "react";
import WmsService from "../../../../shared/wms-service";
import { devbugData } from "../../../../libs/methods";
import { editiontoken } from "../../../../actions/vidromaps";
import Select from "react-select";
import { FormattedMessage } from "react-intl";
const LayersForm = (props) => {
  const { id, name, setLayers, layers, token } = props;
  const [availableLayers, setAvailableLayers] = useState([]);
  const [error, setError] = useState(null);
  const [reading, setReading] = useState(false);
  const [mapToken, setMapToken] = useState(null);
  const [errorLayers, setErrorLayers] = useState(null);
  const [errorContent, setErrorContent] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (!availableLayers || availableLayers.length === 0) return;
    if (layers === "") return;
    const layersArray = layers.split(",");
    if (layersArray.length > 0) {
      let parsedLayers = [];
      layersArray.forEach((element) => {
        if (availableLayers.filter((e) => e.value === element).length > 0) {
          parsedLayers.push({ value: element, label: element });
        } else {
          setErrorLayers(true);
          setErrorContent(element);
        }
      });
      setSelectedOption(parsedLayers);
    }
  }, [availableLayers, layers]);

  useEffect(() => {
    if (!mapToken) return;
    setReading(true);
    WmsService.getCapabilities({
      api_uri: devbugData(),
      project_id: id,
      name,
      token: mapToken,
    })
      .then((msg) => {
        let parsedLayers = [];
        if (msg.layers) {
          msg.layers.forEach((element) => {
            parsedLayers.push({ value: element.Name, label: element.Name });
          });
          setAvailableLayers(parsedLayers);
        }
      })
      .catch((e) => {
        setError(true);
        if (e.data) {
          setErrorContent(e.data.error);
        } else {
          setErrorContent("Error reading project");
        }
      })
      .finally(() => {
        setReading(false);
      });
  }, [id, name, mapToken]);

  useEffect(() => {
    if (!token) return;

    editiontoken(id, name, token)
      .then(([res, code]) => {
        if (code === 200) {
          setMapToken(res.message);
        } else {
          setError(true);
          setErrorContent("Error generating edition token");
        }
      })
      .catch((e) => {
        console.error(e);
        setError(true);
        setErrorContent("Error generating edition token");
      });
  }, [id, name, token]);

  useEffect(() => {
    if (!selectedOption) return;
    let layers = "";
    selectedOption.forEach((element) => {
      layers += `${element.value},`;
    });
    setLayers(layers.slice(0, -1));
  }, [selectedOption, setLayers]);

  return (
    <React.Fragment>
      {id === null && (
        <div className="form-group mt-2">
          <label>
            <FormattedMessage id="vidromaps-layers-load" />
          </label>
        </div>
      )}

      {id !== null && (
        <div className="form-group mt-2">
          <label>
            <FormattedMessage id="vidromaps-layers" />
          </label>
          {error && (
            <div className="alert alert-danger" role="alert">
              {errorContent}
            </div>
          )}
          {errorLayers && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="vidromaps-layer-error" />:<br></br>
              <b>{errorContent}</b>
            </div>
          )}
          {reading && (
            <div className="alert alert-warning" role="alert">
              <FormattedMessage id="vidromaps-reading-project" />
            </div>
          )}
          {!reading && !errorLayers && (
            <Select
              value={selectedOption}
              onChange={setSelectedOption}
              isMulti
              options={availableLayers}
            />
          )}
          <small id="passwordHelpBlock" className="form-text text-muted">
            <FormattedMessage id="vidromaps-layers-help" />
          </small>
        </div>
      )}
    </React.Fragment>
  );
};

export default LayersForm;

import { unset } from "lodash";
import { devbugData, dateFormat } from "../libs/methods";
import { request } from "../shared/api-service";
export async function getLogs(bucket, filters, date_ini, date_end, token) {
  let req = `${devbugData()}/logs/${bucket}?`;
  if (filters.pagination) {
    if (filters.pagination.page) req = `${req}&page=${filters.pagination.page}`;
    if (filters.pagination.items)
      req = `${req}&items=${filters.pagination.items}`;
  }
  if (date_ini) req = `${req}&from=${dateFormat(date_ini)}`;
  if (date_end) req = `${req}&to=${dateFormat(date_end)}`;

  /* console.log(
    "culo",
    /* dateFormat(filters.date_ini),
    filters.date_ini.getFullYear(),
    filters.date_ini.getMonth() + 1,
    filters.date_ini.getDate()
   
  );*/
  for (let key in filters) {
    if (key !== "pagination" && filters[key])
      req = `${req}&${key}=${filters[key]}`;
  }
  //if (datefrom) req = `${req}datefrom=${datefrom}`;

  return request(req, {}, "GET", token);
}
export async function getLog(bucket, id, token) {
  let req = `${devbugData()}/logs/${bucket}/${id}`;
  return request(req, {}, "GET", token);
}

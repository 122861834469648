import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import classNames from "classnames";
//translate
import translate from "../../../i18n/translate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
//actions
import {
  getVidromaps,
  deleteMap,
  getMap,
  previewMap,
  publishMap,
} from "../../../actions/vidromaps";

//libs
import { getAuth } from "../../../libs/cookies";

//Component
import Card from "../../../components/card";
import Button from "../../../components/button";
import Loading from "../../../components/loading";
import Pagination from "../../../components/pagination";
import Map from "../../../components/icons/map";
import Register from "../register";
import Preview from "../../../components/preview";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      showAdd: false,
      currentMap: null,
      preview: null,
      vidromap: null,
      errorCode: null,
      errorMessage: null,
      page: null,
      editButtonDisabled: false,
      filters: {
        name: "",
        pagination: {
          page: "1",
          items: "10",
        },
      },
    };
  }

  componentDidMount() {
    this.setState({ userId: getAuth().id, type: getAuth().type });
    this.getVidromaps();
  }

  getVidromaps = async () => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const [res, code] = await getVidromaps(this.state.filters, getAuth().token);
    if (code === 200) {
      this.setState({ vidromap: res.message });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };

  changeHandler = (e) => {
    this.setState({ search: e.target.value });
  };

  search = (e) => {
    e.preventDefault();
    const { filters, search } = this.state;
    filters.name = search;
    filters.pager.page = 1;
    this.getVidromaps();
  };

  showRegister = (e) => {
    this.setState({ currentMap: null, showAdd: true });
  };

  close = (reload = false, disable = false) => {
    if (reload) this.getVidromaps();

    this.setState({
      showAdd: false,
      currentMap: null,
      preview: null,
      editButtonDisabled: false,
    });
    if (disable) {
      this.setState({
        editButtonDisabled: true,
      });
      setTimeout(() => {
        this.setState({
          editButtonDisabled: false,
        });
      }, 5000);
    }
  };

  remove = async (id) => {
    this.resetError();
    if (
      window.confirm(
        this.props.intl.formatMessage({ id: "herramientas-confirm-alert" })
      )
    ) {
      this.props.dispatch({ type: "SET_LOADING", loading: true });
      const [res, code] = await deleteMap(id, getAuth().token);
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      if (code === 200) {
        this.getVidromaps();
      } else {
        this.setState({
          errorCode: code,
          errorMessage: res.error,
        });
      }
    }
  };

  showUpdate = async (id) => {
    this.resetError();
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const [res, code] = await getMap(id, getAuth().token);
    this.props.dispatch({ type: "SET_LOADING", loading: false });
    //console.log(res, code);
    if (code === 200) {
      this.setState({ currentMap: res.message, showAdd: true });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    } else {
      this.setState({
        errorCode: code,
        errorMessage: res.error,
      });
    }
  };
  handleOnChange = (e) => {
    this.setState({ search: e.target.value });
  };
  resetError = () => {
    this.setState({
      errorCode: null,
      errorMessage: null,
    });
  };
  preview = async (id) => {
    this.resetError();
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const [res, code] = await previewMap(id, getAuth().token);
    this.props.dispatch({ type: "SET_LOADING", loading: false });
    if (code === 200) {
      this.setState({ preview: res.message, currentMap: { id: id } });
    } else {
      this.setState({
        errorCode: code,
        errorMessage: res.error,
      });
    }
  };
  publish = async (id, published) => {
    this.resetError();
    let message = this.props.intl.formatMessage({
      id: "herramientas-confirm-publish",
    });
    if (published === 0)
      message = this.props.intl.formatMessage({
        id: "herramientas-confirm-unpublish",
      });
    if (window.confirm(message)) {
      this.props.dispatch({ type: "SET_LOADING", loading: true });
      const [res, code] = await publishMap(getAuth().token, id, published);
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      if (code === 200) {
        if (published === 0) {
          this.setState({ preview: null, currentMap: null });
        } else {
          this.setState({
            preview: res.message,
            currentMap: { id: id },
          });
        }
      } else {
        this.setState({
          errorCode: code,
          errorMessage: res.error,
        });
      }
      this.getVidromaps();
    }
  };
  paginate = (e) => {
    const { filters } = this.state;
    filters.pagination.page = e.target.value;
    this.setState({ filters });
    this.getVidromaps();
  };

  handleChangeNumberOfColum = (e) => {
    const { filters } = this.state;
    filters.pagination.items = e.target.value;
    filters.pagination.page = 1;
    this.setState({ filters });
    this.getVidromaps();
  };

  nextPage = (page) => {
    const { filters } = this.state;
    page++;
    if (page <= this.state.vidromap.pagination.pages) {
      filters.pagination.page = page;
      this.setState({ filters });
      this.getVidromaps();
    }
  };

  prevPage = (page) => {
    const { filters } = this.state;
    page--;
    if (page > 0) {
      filters.pagination.page = page;
      this.setState({ filters });
      this.getVidromaps();
    }
  };

  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        {(isEmpty(this.state.vidromap) || this.props.loading) && <Loading />}
        {!isEmpty(this.state.vidromap) && (
          <div className="herramientas-administrativas-content">
            {!this.state.showAdd && !this.state.preview && (
              <React.Fragment>
                <Card icon={<Map color="#4b5056" />} title={"VidroMaps"}>
                  <div className="banner-header-content">
                    <Button superBlue onClick={(e) => this.showRegister(e)}>
                      {translate("herramientas-banner-button-add")}
                    </Button>
                  </div>
                </Card>
                <div className="banner-table-content">
                  <ErrorMessage
                    errorCode={this.state.errorCode}
                    errorMessage={this.state.errorMessage}
                  />
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">{translate("global-id")}</th>
                        <th scope="col">{translate("global-name")}</th>
                        {this.state.type === "admin" && (
                          <th scope="col">Company</th>
                        )}

                        <th scope="col">Geoservice</th>
                        <th scope="col">{translate("global-published")}</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.vidromap.data.length > 0 && (
                        <React.Fragment>
                          {this.state.vidromap.data.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className={(index + 1) % 2 ? "impar" : "par"}
                              >
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                {this.state.type === "admin" && (
                                  <td>{item.company_id}</td>
                                )}
                                <td>
                                  {item.geoservice === "0" && (
                                    <span>No geoservice</span>
                                  )}
                                  {item.geoservice !== "0" && (
                                    <span>{item.geoservice}</span>
                                  )}
                                </td>
                                <td>
                                  {item.published === 1 && (
                                    <Button
                                      red
                                      onClick={(e) => this.publish(item.id, 0)}
                                    >
                                      {translate("global-unpublish")}
                                    </Button>
                                  )}
                                  {item.published === 0 && (
                                    <Button
                                      green
                                      onClick={(e) => this.publish(item.id, 1)}
                                    >
                                      {translate("global-publish")}
                                    </Button>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    blue
                                    onClick={(e) => this.preview(item.id, 1)}
                                  >
                                    {translate("global-preview")}
                                  </Button>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      className={classNames(
                                        "w-100",
                                        "btn btn-primary",
                                        {
                                          "btn btn-secondary":
                                            this.state.editButtonDisabled,
                                        }
                                      )}
                                      type="button"
                                      onClick={(e) => this.showUpdate(item.id)}
                                      disabled={this.state.editButtonDisabled}
                                    >
                                      <FontAwesomeIcon icon={faPenToSquare} />
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      className="w-100 btn btn-danger"
                                      type="button"
                                      onClick={(e) => this.remove(item.id)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                  pagination={this.state.vidromap.pagination}
                  paginate={this.paginate}
                  prevPage={this.prevPage}
                  nextPage={this.nextPage}
                  total={this.state.vidromap.total}
                />
              </React.Fragment>
            )}

            {this.state.showAdd && (
              <Register
                close={this.close}
                token={getAuth().token}
                vidroMap={this.state.currentMap}
              />
            )}
            {this.state.preview && (
              <Preview
                data={this.state.preview}
                close={this.close}
                publish={this.publish}
                id={this.state.currentMap.id}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    loading: state.loading.loading,
  };
};
const ErrorMessage = ({ errorCode, errorMessage }) => {
  if (!errorCode) return null;
  return (
    <div className="alert alert-danger" role="alert">
      {errorCode === 400
        ? translate("global-error-bad-request")
        : errorCode === 401
        ? errorMessage
        : errorCode === 402
        ? errorMessage
        : errorCode === 403
        ? translate("global-unauthorized")
        : errorCode === 404
        ? translate("vidromaps-map-not-found")
        : errorCode === 500
        ? "Ha ocurrido un error. Inténtelo más tarde."
        : errorCode === 502
        ? translate("vidromaps-error-delete-qgis")
        : `Error ${errorCode}`}
    </div>
  );
};
export default injectIntl(connect(mapStateToProps)(List));

import React, { useImperativeHandle, forwardRef, useState } from "react";
import { updateFiles, downloadFile } from "../../../../actions/vidromaps";
import translate from "../../../../i18n/translate";
import Notice from "../../../../components/notice";
import Done from "../../../../components/icons/done";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { devbugData } from "../../../../libs/methods";
const MapFilesForm = forwardRef((props, ref) => {
  const [filesUpdated, setFilesUpdated] = useState(false);
  const [errorGeoproject, setEerrorGeoproject] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(null);
  const [errorData, setEerrorData] = useState(false);
  const [fileLabel, setFileLabel] = useState(
    <FormattedMessage id="herramientas-banner-input-file-select" />
  );
  const [fileLabelData, setFileLabelData] = useState(
    <FormattedMessage id="herramientas-banner-input-file-select" />
  );

  const [zipFile] = useState(props.files ? props.files.zip : null);
  const [qgisFile] = useState(props.files ? props.files.qgis : null);

  //display error form parent component
  useImperativeHandle(ref, () => ({
    displayError(type, what) {
      if (type === "geoproject") {
        setEerrorGeoproject(what);
      } else if (type === "data") {
        setEerrorData(what);
      }
    },
  }));

  const requestDownloadFile = (e, file) => {
    e.preventDefault();
    downloadFile(file, props.token);
  };

  const updateFilesHandler = (e) => {
    e.preventDefault();
    setEerrorGeoproject(false);
    if (
      props.geoproject === "" &&
      props.geoproject === null &&
      props.geoprojectdata === "" &&
      props.geoprojectdata === null
    )
      return false;
    props.dispatch({ type: "SET_LOADING", loading: true });
    updateFiles(props.id, props.geoproject, props.geoprojectdata, props.token)
      .then((res) => {
        props.setGeoprojectdata(null);
        props.setGeoprojectdata(null);
        props.close(true, true);
      })
      .catch((e) => {
        setEerrorGeoproject(true);
        setEerrorData(true);
        console.error(e);
        setLocalErrorMessage(e.error);
      })
      .finally(() => {
        props.dispatch({ type: "SET_LOADING", loading: false });
      });
    return false;
  };
  const handleOnChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "geoproject") {
      props.setGeoproject(files[0]);
      setFileLabel(files[0].name);
    } else if (name === "geoprojectdata") {
      props.setGeoprojectdata(files[0]);
      setFileLabelData(files[0].name);
    }
  };
  return (
    <React.Fragment>
      {filesUpdated && (
        <Notice
          icon={<Done />}
          closeModal={props.closeModal}
          name={translate("notice-files-updated")}
          button={translate("notice-button-acepted")}
          textTwo={translate("notice-text-file-updated")}
        />
      )}
      {props.geoservice === "QGIS" && (
        <React.Fragment>
          <div className="form-group">
            <label>
              <FormattedMessage id="herramientas-map-input-file" />
            </label>
            {localErrorMessage && (
              <div className="alert alert-danger" role="alert">
                {localErrorMessage}
              </div>
            )}
            <div className="custom-file">
              <input
                type="file"
                name="geoproject"
                className={classNames("custom-file-input", {
                  "is-invalid": errorGeoproject,
                })}
                id="customFileLang"
                lang="es"
                accept=".qgs,.qgz"
                onChange={handleOnChange}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                {fileLabel}
              </label>
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              <FormattedMessage id="vidromaps-qgis-help" />
            </small>
          </div>
          {qgisFile && (
            <>
              <small id="passwordHelpBlock" className="form-text text-muted">
                <FormattedMessage id="vidromaps-current-file" />
              </small>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => requestDownloadFile(e, qgisFile)}
              >
                <FormattedMessage id="global-download" /> {qgisFile}
              </button>
            </>
          )}
          <div className="form-group">
            <label>
              <FormattedMessage id="herramientas-map-input-file-data" />
            </label>
            <div className="custom-file">
              <input
                type="file"
                name="geoprojectdata"
                className={classNames("custom-file-input", {
                  "is-invalid": errorData,
                })}
                id="geoprojectdata"
                accept=".zip"
                lang="es"
                onChange={handleOnChange}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                {fileLabelData}
              </label>
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              <FormattedMessage id="vidromaps-zip-help" />
            </small>
          </div>
          {zipFile && (
            <>
              <small id="passwordHelpBlock" className="form-text text-muted">
                <FormattedMessage id="vidromaps-current-file" />
              </small>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => requestDownloadFile(e, zipFile)}
              >
                <FormattedMessage id="global-download" /> {zipFile}
              </button>
            </>
          )}
        </React.Fragment>
      )}

      {props.geoservice === "QGIS" && props.id !== null && (
        <div className="form-group mt-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) => updateFilesHandler(e)}
          >
            <FormattedMessage id="vidromaps-button-update-files" />
          </button>
        </div>
      )}
    </React.Fragment>
  );
});
export default MapFilesForm;

import { createStore, combineReducers } from "redux";
import auth from "./data/auth";
import loading from "./data/loading";
import administracion from "./data/administracion";
import members from "./data/members";
import modal from "./data/modal";

const reducers = combineReducers({
  auth,
  loading,
  administracion,
  members,
  modal,
});

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

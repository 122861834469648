import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import translate from "../../../../i18n/translate";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const ExtentForm = forwardRef((props, ref) => {
  const [errorExtent, setErrorExtent] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(false);
  const [check, setCheck] = useState(props.geoservice === 0 ? false : true);

  useEffect(() => {
    if (props.geoservice !== 0) {
      setCheck(true);
    }
  }, [props.geoservice]);

  //disable check if custom value is set
  useEffect(() => {
    if (props.extent !== "") {
      setCheckDisabled(true);
    } else {
      setCheckDisabled(false);
    }
  }, [props.extent]);
  //display error form parent component
  useImperativeHandle(ref, () => ({
    displayError(what) {
      setErrorExtent(what);
    },
  }));
  const handleOnChange = (e) => {
    const { value } = e.target;
    let ext = value.replace(/\s/g, "");
    if (value !== "") {
      setErrorExtent(false);
      if (ext.match("^[0-9.,]+$")) {
        ext = ext.split(",");
        if (ext.length !== 4 && value !== "") {
          setErrorExtent(true);
        }
      } else {
        setErrorExtent(true);
      }
    }
    props.setExtent(value);
  };

  const handleCheckbox = (e) => {
    if (check) {
      setCheck(false);
    } else {
      setErrorExtent(false);
      setCheck(true);
    }
  };
  return (
    <React.Fragment>
      <div className="form-group">
        <label>
          <FormattedMessage id="vidromaps-extent" />
        </label>
        {errorExtent && (
          <div className="alert alert-danger" role="alert">
            <FormattedMessage id="vidromaps-error-extent" />
          </div>
        )}
        {props.geoservice !== 0 && (
          <div>
            <input
              disabled={checkDisabled}
              type="checkbox"
              name="use-project-extent"
              id="use-project-extent"
              checked={check}
              onChange={(e) => handleCheckbox(e)}
            />
            <label className="ml-1">
              {translate("vidromaps-use-project-extent")}
            </label>
          </div>
        )}
        {!check && (
          <React.Fragment>
            <input
              type="text"
              className={classNames("form-control", {
                "is-invalid": errorExtent,
              })}
              name="extent"
              placeholder="100000,200000,300000,400000"
              value={props.extent}
              onChange={handleOnChange}
            />
            {props.geoservice !== 0 && (
              <small id="passwordHelpBlock" className="form-text text-muted">
                <FormattedMessage id="vidromaps-extent-help" />
              </small>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});
export default ExtentForm;

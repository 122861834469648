import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";

//translate
import translate from "../../../i18n/translate";

//actions
import { getLogs } from "../../../actions/logs";

//libs
import { getAuth } from "../../../libs/cookies";

//Component
import Detail from "../detail";
import IconHerramienta from "../../../components/icons/herramienta";
import Pagination from "../../../components/pagination";
import Card from "../../../components/card";
import Threepoints from "../../../components/icons/threepoints";
import Form from "./form";
import Logstatus from "../../../components/logstatus";
import Loading from "../../../components/loading";
// style
import "../index.scss";
class List extends Component {
  constructor(props) {
    super(props);
    let today = new Date();
    let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.state = {
      userId: null,
      date_ini: firstDayOfMonth,
      tableData: null,
      errorCode: null,
      errorMessage: null,
      bucket: null,
      logId: null,
      showDetail: false,
      page: null,
      date_ini: firstDayOfMonth,
      date_end: new Date(),
      filters: {
        ip: "",
        us: "",

        pagination: {
          page: "1",
          items: "10",
        },
      },
    };
  }

  componentDidMount() {
    let today = new Date();
    let currentMonth = today.getMonth() + 1;
    if (currentMonth < 10) {
      currentMonth = `0${currentMonth}`;
    }
    this.setState(
      {
        userId: getAuth().id,
        type: getAuth().type,
        bucket: `LOG_API_${currentMonth}_${today.getFullYear()}`,
      },
      () => {
        this.getLogs(this.state.filters);
      }
    );
  }

  getLogs = async (filters) => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const [res, code] = await getLogs(
      this.state.bucket,
      filters,
      this.state.date_ini,
      this.state.date_end,
      getAuth().token
    );
    if (code === 200) {
      this.setState({ tableData: res.message });

      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };

  changeHandler = (e) => {
    this.setState({ search: e.target.value });
  };

  showDetail = (id) => {
    this.setState({ logId: id, showDetail: true });
  };

  close = (reload = false) => {
    this.setState({ showDetail: false }, () => {
      this.getLogs(this.state.filters);
    });
  };

  handleOnChange = (e) => {
    const { value, name } = e.target;
    let fil = this.state.filters;
    fil[name] = value;

    //this.setState({ filters: fil });
  };
  applyFilter = () => {
    this.getLogs(this.state.filters);
  };
  resetFilters = () => {
    let pagin = this.state.filters.pagination;
    /* this.setState({ filters: { pagination: pagin } }, () => {
      this.getLogs(this.state.filters);
    });*/
  };
  resetError = () => {
    this.setState({
      errorCode: null,
      errorMessage: null,
    });
  };

  handleChangeDateEnd = (date) => {
    this.setState({
      date_end: date,
    });
  };
  handleChangeDateIni = (date) => {
    this.setState({
      date_ini: date,
    });
  };
  handleChangeBucket = (e) => {
    this.setState(
      {
        bucket: e.target.value,
      },
      () => {
        this.getLogs(this.state.filters);
      }
    );
  };

  fomatDate = (date) => {
    let dt = new Date(Date.parse(date));
    return `${dt.getMonth() + 1} - ${dt.getFullYear()}`;
  };
  paginate = (e) => {
    const { filters } = this.state;
    filters.pagination.page = e.target.value;
    this.setState({ filters }, () => {
      this.getLogs(this.state.filters);
    });
  };

  handleChangeNumberOfColum = (e) => {
    const { filters } = this.state;
    filters.pagination.items = e.target.value;
    filters.pagination.page = 1;
    this.setState({ filters }, () => {
      this.getLogs(this.state.filters);
    });
  };

  nextPage = (page) => {
    const { filters } = this.state;
    page++;
    if (page <= this.state.tableData.pagination.pages) {
      filters.pagination.page = page;
      this.setState({ filters }, () => {
        this.getLogs(this.state.filters);
      });
    }
  };

  prevPage = (page) => {
    const { filters } = this.state;
    page--;
    if (page > 0) {
      filters.pagination.page = page;
      this.setState({ filters }, () => {
        this.getLogs(this.state.filters);
      });
    }
  };

  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        {(isEmpty(this.state.tableData) || this.props.loading) && <Loading />}

        <div className="stats">
          <React.Fragment>
            <div className="statsolecams-content">
              {!isEmpty(this.state.tableData) && !this.state.showDetail && (
                <Card icon={<IconHerramienta color="#4b5056" />} title={"Logs"}>
                  <div className="stats-ol">
                    <Form
                      handleOnChange={this.handleOnChange}
                      handleChangeBucket={this.handleChangeBucket}
                      handleChangeDateIni={this.handleChangeDateIni}
                      handleChangeDateEnd={this.handleChangeDateEnd}
                      date_ini={this.state.date_ini}
                      date_end={this.state.date_end}
                      buckets={this.state.tableData.buckets}
                      bucket={this.state.bucket}
                      applyFilter={this.applyFilter}
                      filters={this.state.filters}
                      resetFilters={this.resetFilters}
                    />
                  </div>
                </Card>
              )}
              {!isEmpty(this.state.tableData) && !this.state.showDetail && (
                <div className="table-content">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {translate("statistics-table-date")}
                        </th>
                        <th scope="col">
                          {translate("administration-data-id")}
                        </th>

                        <th scope="col">{translate("global-event")}</th>
                        <th scope="col">Status</th>

                        <th scope="col">IP</th>
                        <th scope="col">
                          <b>{translate("global-detail")}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tableData.data.length > 0 && (
                        <React.Fragment>
                          {this.state.tableData.data.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className={(index + 1) % 2 ? "impar" : "par"}
                              >
                                <td>{item.fecha}</td>
                                <td>
                                  {item.user}
                                  <span className="small">{item.email}</span>
                                  <span className="small">
                                    ID {item.user_id}
                                  </span>
                                </td>
                                <td>
                                  {item.msg}
                                  <span className="small">{item.evt}</span>
                                </td>
                                <td>
                                  <Logstatus status={item.status} />
                                </td>

                                <td>{item.ip}</td>
                                <td>
                                  <Threepoints
                                    item={item._id}
                                    action={this.showDetail}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>

                  <Pagination
                    handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                    pagination={this.state.tableData.pagination}
                    paginate={this.paginate}
                    prevPage={this.prevPage}
                    nextPage={this.nextPage}
                    total={this.state.tableData.total}
                  />
                </div>
              )}
              {this.state.showDetail && (
                <Detail
                  close={this.close}
                  bucket={this.state.bucket}
                  logId={this.state.logId}
                />
              )}
            </div>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    loading: state.loading.loading,
  };
};
const ErrorMessage = ({ errorCode, errorMessage }) => {
  if (!errorCode) return null;
  return (
    <div className="alert alert-danger" role="alert">
      {errorCode === 400
        ? translate("global-error-bad-request")
        : errorCode === 401
        ? errorMessage
        : errorCode === 402
        ? errorMessage
        : errorCode === 403
        ? translate("global-unauthorized")
        : errorCode === 404
        ? translate("vidromaps-map-not-found")
        : errorCode === 500
        ? "Ha ocurrido un error. Inténtelo más tarde."
        : `Error ${errorCode}`}
    </div>
  );
};
export default injectIntl(connect(mapStateToProps)(List));

import React, { useEffect, useState } from "react";
import { getBackgrounds } from "../../../../actions/backgrounds";
//import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const BackgroundsForm = (props) => {
  const [backgrounds, setBackgrounds] = useState([]);
  // on component mount
  useEffect(() => {
    getBackgrounds(props.token).then(([res, code]) => {
      if (code === 200) {
        setBackgrounds(res.message);
      }
    });
  }, []);
  const handleOnChange = (e) => {
    const { value } = e.target;
    props.setBackground_id(value);
  };
  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="vidromaps-background" />
      </label>
      <select
        name="background_id"
        className="form-control"
        onChange={handleOnChange}
        value={props.id}
      >
        {backgrounds.map((background, index) => {
          return (
            <option key={index} value={background.id}>
              {background.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default BackgroundsForm;

import axios from "axios";
import { WMSCapabilities, WMTSCapabilities } from "ol/format/";

const getCapabilities = (props) => {
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}/wms?service=WMS&VERSION=1.0.0&REQUEST=GetCapabilities`;
    console.log(`GetCapabilities`, requestUrl);
    if (props.project_id) {
      requestUrl += `&project_id=${props.project_id}`;
    }
    if (props.name) {
      requestUrl += `&name=${props.name}`;
    }

    requestUrl += `&type=vidromap`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        console.log(`GetCapabilities reponse`, response);

        try {
          var parser = new WMSCapabilities();
          var capabilities = parser.read(response.data);
          let extent = null;
          let srid = null;
          let GetFeatureInfo = null;
          console.log("getCapabilities response", capabilities);
          if (
            typeof capabilities.Capability.Layer.BoundingBox != "undefined" &&
            typeof capabilities.Capability.Layer.BoundingBox[0] != "undefined"
          ) {
            extent = capabilities.Capability.Layer.BoundingBox[0].extent;
            srid = capabilities.Capability.Layer.BoundingBox[0].crs;
          } else {
            extent =
              capabilities.Capability.Layer.Layer[0].BoundingBox[0].extent;
          }
          if (typeof capabilities.Capability.Request != "undefined") {
            if (
              typeof capabilities.Capability.Request.GetFeatureInfo !=
              "undefined"
            )
              GetFeatureInfo = true;
          }
          let res = {
            capabilities: capabilities,
            layers: capabilities.Capability.Layer.Layer,
            extent: extent,
            epsg: srid,
            GetFeatureInfo: GetFeatureInfo,
          };
          resolve(res);
        } catch (e) {
          console.error("getCapabilities error", e);
          if (response.data) reject({ data: { error: response.data } });
          reject(e);
        }
      })
      .catch((error) => {
        console.error("getCapabilities error request", error);
        let e = {
          error,
        };
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          e.data = error.response.data;
          e.code = error.response.status;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
        reject(e);
      });
  });
};

const getWMTSCapabilities = (props) => {
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}wmts?service=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities`;

    if (props.project_id) {
      requestUrl += `&project_id=${props.project_id}`;
    }
    if (props.name) {
      requestUrl += `&name=${props.name}`;
    }
    if (props.type) {
      requestUrl += `&type=${props.type}`;
    }
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        console.log(`getWMTSCapabilities`, response);
        try {
          let parser = new WMTSCapabilities();
          resolve(parser.read(response.data));
        } catch (e) {
          console.error("getWMTSCapabilities error", e);
          reject(e);
        }
      })
      .catch((error) => {
        console.error("getWMTSCapabilities error", error);
        reject(error);
      });
  });
};
const getWMTSCustombg = (props) => {
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}custombgwtms?service=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities`;

    if (props.project_id) {
      requestUrl += `&project_id=${props.project_id}`;
    }
    if (props.name) {
      requestUrl += `&name=${props.name}`;
    }
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        console.log(`getWMTSCustombg`, response);
        try {
          let parser = new WMTSCapabilities();
          resolve(parser.read(response.data));
        } catch (e) {
          console.error("getWMTSCustombg error", e);
          reject(e);
        }
      })
      .catch((error) => {
        console.error("getWMTSCustombg error", error);
        reject(error);
      });
  });
};
const wmsInfo = (props) => {
  return new Promise((resolve, reject) => {
    if (!props.activeLayer) reject("no active layer");
    let infoOptionsObj = {
      INFO_FORMAT: "text/xml",
    };
    if (typeof props.layers != "undefined") {
      console.log("wmsInfo()", props.layers);
      infoOptionsObj["QUERY_LAYERS"] = props.layers;
    }

    let _source = null;
    //find active layer source
    props.map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === props.activeLayer) {
        _source = layer.getSource();
      }
    });

    var url = _source.getFeatureInfoUrl(
      props.coordinates,
      props.map.getView().getResolution() * 5,
      props.map.getView().getProjection(),
      infoOptionsObj
    );
    let requestUrl = `${url}&project_id=${props.project_id}&name=${props.name}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        console.log(`wmsInfo`, response);
        try {
          resolve(response);
        } catch (e) {
          console.error("wmsInfo error", e);
          reject(e);
        }
      })
      .catch((error) => {
        console.error("wmsInfo error", error);
        reject(error);
      });
  });
};
const WFSrequest = (props) => {
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}${props.request}&project_id=${props.project_id}&name=${props.name}`;

    console.log(`WFSrequest requestUrl`, requestUrl);
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        console.log(`WFSrequest`, response);
        try {
          resolve(response);
        } catch (e) {
          console.error("WFSrequest error", e);
          reject(e);
        }
      })
      .catch((error) => {
        console.error("WFSrequest error", error);
        reject(error);
      });
  });
};

const ProxyWFSrequest = (api_uri, token, request) => {
  return new Promise((resolve, reject) => {
    let requestUrl = `${api_uri}proxygeoserver?uri=${request}`;

    console.log(`WFSrequest requestUrl`, requestUrl);
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        console.log(`WFSrequest`, response.data);
        try {
          resolve(response.data);
        } catch (e) {
          console.error("WFSrequest error", e);
          reject(e);
        }
      })
      .catch((error) => {
        console.error("WFSrequest error", error);
        reject(error);
      });
  });
};

const parseCapabilieties = (xml) => {
  return new Promise((resolve, reject) => {
    try {
      let parser = new WMTSCapabilities();
      resolve(parser.read(xml));
    } catch (e) {
      console.error("parseCapabilieties error", e);
      reject(e);
    }
  });
};

const WmsService = {
  getCapabilities,
  wmsInfo,
  getWMTSCapabilities,
  WFSrequest,
  getWMTSCustombg,
  ProxyWFSrequest,
  parseCapabilieties,
};

export default WmsService;

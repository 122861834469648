import React from 'react';

const NotificationError = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 20">
       <path fill={props.color} d="M10 .833a9.167 9.167 0 1 1 0 18.334A9.167 9.167 0 0 1 10 .833zm0 1.834A7.333 7.333 0 0 0 2.667 10c0 1.696.577 3.254 1.54 4.5L14.5 4.208A7.338 7.338 0 0 0 10 2.667zm0 14.666A7.333 7.333 0 0 0 17.333 10a7.338 7.338 0 0 0-1.54-4.5L5.5 15.792A7.338 7.338 0 0 0 10 17.333z"/>
    </svg>
  )
}

export default NotificationError;
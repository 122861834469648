import React from 'react'

const UserDesktop = (props) => {
  return (
    <svg className="user-destok" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
        <path fill={props.color} id="user" d="M10 17.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2zM10 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0-3C4.477 0 0 4.477 0 10a10 10 0 0 0 20 0c0-5.53-4.5-10-10-10z"/>
    </svg>  
  )
}

export default UserDesktop;
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { getLang } from "../../libs/cookies";

import Menu from "./menu";
import Content from "./content";
import Footer from "./footer";

//style
import "./index.scss";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0,
      footerHeight: 0,
      showMenu: true,
      showSidebar: true,
      showUserMenu: true,
      isOpen: false,
      isMobile: true,
    };
    this.previousWidth = -1;
  }

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile,
      });
    }

    this.previousWidth = width;
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className="public-layout content d-flex flex-column min-vh-100 is-open container-fluid p-0">
        <ToastContainer autoClose={6000} />
        <Menu />
        <Content cssClass={this.props.cssClass}>{this.props.children}</Content>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Layout);

import React, { Component} from 'react';

import SwaggerUI from './swagger';


class Contact extends Component {
    render() {
        return(
            <div>
                <SwaggerUI url="/docs/api-docs.json"/>
            </div>
        )
    }
}

export default Contact

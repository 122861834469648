import React, { Component } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";

//translate
import { FormattedMessage } from "react-intl";

//actions
import { updateMember, addMember } from "../../../actions/members";

// components
import Card from "../../../components/card";
import Button from "../../../components/button";
import Data from "../../../components/icons/data";

//style
import "../index.scss";

class Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member: {
        id: this.props.member ? this.props.member.id : null,
        name: this.props.member ? this.props.member.name : "",
        email: this.props.member ? this.props.member.email : "",
        status: this.props.member ? Boolean(this.props.member.status) : true,
      },
      errorContent: null,
      errorData: false,
      errorRequest: false,
    };
  }

  update = async () => {
    const { member } = this.state;
    if (member.name === null || member.email === "") {
      this.setState({
        errorData: true,
      });
    } else {
      if (this.state.member.id) {
        updateMember(this.props.token, member).then((res) => {
          console.log(res);
          if (res.data.status === "Failed") {
            this.setState({ errorRequest: true });
          } else {
            this.props.close(true);
          }
        });
      } else {
        addMember(this.props.token, member)
          .then((res) => {
            this.props.close(true);
          })
          .catch((e) => {
            this.setState({
              errorContent: e.error,
              errorData: true,
            });
          });
      }
    }
  };

  handleOnChange = (e) => {
    const { member } = this.state;
    const { name, value } = e.target;
    this.setState({
      member: {
        ...member,
        [name]: value,
      },
    });
  };

  handleOnChangeStatus = (checked) => {
    const { member } = this.state;
    this.setState({
      member: {
        ...member,
        status: checked === true ? 1 : 0,
      },
      status: checked,
    });
  };

  render() {
    return (
      <div className="register-banner-modal">
        <Card
          icon={<Data />}
          title={<FormattedMessage id="herramientas-members-name" />}
          modal
          closeModal={this.props.close}
        >
          <div className="banner-body">
            {this.state.errorData && (
              <div className="alert alert-danger" role="alert">
                <FormattedMessage id="herramientas-error-data" />
              </div>
            )}
            {this.state.errorContent && (
              <div className="alert alert-danger" role="alert">
                {this.state.errorContent}
              </div>
            )}
            {this.state.errorRequest && (
              <div className="alert alert-danger" role="alert">
                <FormattedMessage id="herramientas-landing-error-request-update" />
              </div>
            )}

            <form className="banner-modal">
              <div className="form-group">
                <label>
                  <FormattedMessage id="global-name" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={this.state.member.name}
                  onChange={this.handleOnChange}
                />
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage id="global-email" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={this.state.member.email}
                  onChange={this.handleOnChange}
                />
              </div>
              {this.state.member.id === null && (
                <div className="form-group">
                  <label>
                    <FormattedMessage id="login-password" />
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="pwd"
                    onChange={this.handleOnChange}
                  />
                </div>
              )}
              <div className="form-group">
                <label className="swith-label">
                  {this.state.member.status === 1 && (
                    <FormattedMessage id="global-active" />
                  )}
                  {this.state.member.status === 0 && (
                    <FormattedMessage id="global-deactivated" />
                  )}
                </label>
                <Switch
                  onChange={this.handleOnChangeStatus}
                  checked={this.state.member.status}
                />
              </div>
            </form>
          </div>
          <div className="button-content">
            <Button rounded onClick={(e) => this.update()}>
              <FormattedMessage id="herramientas-banner-register-save" />
            </Button>
            <Button rounded onClick={(e) => this.props.close()} red>
              <FormattedMessage id="herramientas-banner-register-cancel" />
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Update);

import React, { Component } from "react";
import Layout from "../../components/layout";

//Component
import Doc from "./doc";

class Documentation extends Component {
  render() {
    return (
      <Layout>
        <Doc />
      </Layout>
    );
  }
}

export default Documentation;

import React from 'react';
import { click } from '../../libs/methods';

const Calendar = (props) => {
  return (
    <svg onClick={e => click(props.id)} className="icon-calendar" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
        <path fill="#4B5056"d="M6 9v2H4V9h2zm4 0v2H8V9h2zm4 0v2h-2V9h2zm2-7c1.105 0 2 .895 2 2v14c0 1.105-.895 2-2 2H2c-1.11 0-2-.9-2-2V4c0-1.105.895-2 2-2h1V0h2v2h8V0h2v2h1zm0 16V7H2v11h14zM6 13v2H4v-2h2zm4 0v2H8v-2h2zm4 0v2h-2v-2h2z"/>
    </svg>
  )
}

export default Calendar;
import React, { Component } from "react";
import Layout from "../../components/layout";

//Component
import Datos from "./datos";
import Members from "./members";

class administracion extends Component {
  render() {
    const { match } = this.props;
    return (
      <Layout>
        {match.url === "/administracion/datos" && <Datos />}
        {match.url === "/administracion/members" && <Members />}
      </Layout>
    );
  }
}

export default administracion;

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./index.scss";
import Success from "../icons/success";
import Failed from "../icons/failed";

class Logstatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderStatus = (st) => {
    switch (st.toLowerCase()) {
      case "ok":
      case "success":
      case "accepted":
        return <Success />;
      case "ko":
      case "failed":
      case "error":
        return <Failed />;
      default:
        return <span>{st}</span>;
    }
  };
  render() {
    return this.renderStatus(this.props.status);
  }
}

export default Logstatus;

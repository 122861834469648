import React, { Component } from "react";
import classNames from "classnames";

//translate
import translate from "../../i18n/translate";

//libs
import { setLang, getLang } from "../../libs/cookies";

//style
import "./index.scss";

class Footer extends Component {
  changeLang = (lang) => {
    setLang(lang);
    window.location.reload();
  };

  render() {
    return (
      <footer id="footer">
        <div className="footer-links">
          <p>&copy; {new Date().getFullYear()} VidroSofware SL</p>
          <span className="text-off-app">
            |
            <a
              className="legal"
              href="https://"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate("footer-legal")}
            </a>
            |
            <a
              className="privat"
              href="https://"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate("footer-privacy-policy")}
            </a>
            |
            <a
              className="cookie"
              href="https://"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate("footer-cookie")}
            </a>
          </span>
        </div>
        <div className="footer-idiomas">
          <span
            className={classNames("en", { active: getLang() === "en" })}
            onClick={(e) => this.changeLang("en")}
          >
            {translate("footer-lang-en")}
          </span>
          |
          <span
            className={classNames("es", { active: getLang() === "es" })}
            onClick={(e) => this.changeLang("es")}
          >
            {translate("footer-lang-es")}
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;

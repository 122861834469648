import React, { Component } from "react";
import Layout from "../../components/layout";

//Component
/*import Datos from "./datos";*/
import List from "./list";

class Mapas extends Component {
  render() {
    const { match } = this.props;
    return (
      <Layout>
        <List />
      </Layout>
    );
  }
}

export default Mapas;

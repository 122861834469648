import React from 'react';

const Done = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
            <defs>
                <path id="donea" d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8c.76 0 1.5.11 2.2.31L13.77.74C12.61.26 11.34 0 10 0 4.477 0 0 4.477 0 10c0 2.652 1.054 5.196 2.929 7.071C4.804 18.946 7.348 20 10 20s5.196-1.054 7.071-2.929C18.946 15.196 20 12.652 20 10h-2zM5.91 8.08L4.5 9.5 9 14 19 4l-1.41-1.42L9 11.17 5.91 8.08z"/>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <mask id="doneb" fill="#fff">
                    <use xlinkHref="#donea"/>
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#donea"/>
                <g fill="#4B5056" mask="url(#doneb)">
                    <path d="M0 0H22V22H0z" transform="translate(-1 -1)"/>
                </g>
            </g>
        </svg>  
    )
}

export default Done;

import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { injectIntl } from "react-intl";

import SimpleReactValidator from "simple-react-validator";

//translate
import translate from "../../../i18n/translate";

//libs
import { getAuth } from "../../../libs/cookies";

//actions

import { getMyData, updateMyData } from "../../../actions/administracion";

//Component
import Button from "../../../components/button";
import DataIcon from "../../../components/icons/data";

import Card from "../../../components/card";
import Loading from "../../../components/loading";
import NotificationSuccess from "../../../components/icons/notificationSucces";
import NotificationError from "../../../components/icons/notificationError";

import { getLang } from "../../../libs/cookies";

import validator_locale_es from "../../../assets/simple_react_validator_locale/es.json";

// style
import "./index.scss";

class Datos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: {},
      contracts: {},
      payment_method: [],
      pwd: "",
      old_pwd: "",
      repeatpwd: "",
      errorOldPassword: false,
      errorParameter: false,
      errorRequest: false,
      errorRepeatPassword: false,
      errorPassworFormat: false,
      errorSamePassword: false,
      idContract: "",
      submit: false,
    };
    SimpleReactValidator.addLocale("es", validator_locale_es);
    this.lang = getLang();
    this.validator = new SimpleReactValidator({ locale: this.lang });
  }

  async componentDidMount() {
    await getMyData(getAuth().token, this.props.dispatch);
    this.setState({
      datos: this.props.datos,
      payment_method: this.props.datos.payment_types,
      contracts: this.props.datos.contracts,
    });
  }

  saveData = () => {
    if (this.validator.allValid()) {
      updateMyData(
        this.props.auth.token,
        this.state.datos,
        this.props.dispatch
      );
      toast.success(
        <span>
          <NotificationSuccess color="#ffffff" />
          {translate("administration-success-data")}
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "notif succes-color",
        }
      );
    } else {
      toast.error(
        <span>
          <NotificationError color="#ffffff" />
          {translate("herramientas-error-data")}
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "notif error-color",
        }
      );
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleOnChange = (e) => {
    const { datos } = this.state;
    const { value, name } = e.target;
    this.setState({
      datos: {
        ...datos,
        [name]: value,
      },
    });
  };

  render() {
    const { datos, submit } = this.state;

    return (
      <React.Fragment>
        {(isEmpty(datos) || this.props.loading) && <Loading />}

        {!isEmpty(datos) && !this.props.loading && (
          <div className="data-content">
            <Card icon={<DataIcon />} title={translate("sidebar-adm-data")}>
              <form>
                <div className="form-group">
                  <label>{translate("administration-data-id")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="id"
                    value={datos.id}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label>{translate("administration-data-company")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="empresa"
                    disabled
                    style={{
                      border:
                        submit &&
                        !this.validator.fieldValid(
                          this.props.intl.formatMessage({
                            id: "administration-data-company",
                          })
                        )
                          ? "1px solid #ff043a"
                          : "",
                    }}
                    value={datos.company}
                    onChange={this.handleOnChange}
                  />
                  {this.validator.message(
                    this.props.intl.formatMessage({
                      id: "administration-data-company",
                    }),
                    this.state.datos.empresa,
                    "min: 2|max: 80|required"
                  )}
                </div>

                <div className="form-group">
                  <label>{translate("support-contact-name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={datos.name}
                    onChange={this.handleOnChange}
                  />
                  {this.validator.message(
                    this.props.intl.formatMessage({
                      id: "support-contact-name",
                    }),
                    this.state.datos.name,
                    "min: 6|max: 100|required"
                  )}
                </div>
                <div className="form-group">
                  <label>{translate("global-email")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={datos.email}
                    onChange={this.handleOnChange}
                  />
                  {this.validator.message(
                    this.props.intl.formatMessage({ id: "global-email" }),
                    this.state.datos.email,
                    "email|required"
                  )}
                </div>

                <div className="button-content">
                  <Button rounded onClick={this.saveData} align="center">
                    {translate("administration-data-button")}
                  </Button>
                </div>
              </form>
            </Card>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    loading: state.loading.loading,
    datos: state.administracion.datos,
  };
};

export default injectIntl(connect(mapStateToProps)(Datos));

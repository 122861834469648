import React from "react";

//translate
import translate from "../../i18n/translate";

//components
import Left from ".././icons/left";
import Right from "../icons/right";

//style
import "./index.scss";

const Pagination = (props) => {
  let paggesNumner = [];
  if (props.pagination)
    for (let i = 1; i <= props.pagination.pages; i++) {
      paggesNumner.push(i);
    }

  return (
    <div className="paginate-content">
      {!props.item && props.pagination && (
        <div className="items">
          {translate("default-paginate-items")}
          <select
            className="select-items"
            onChange={props.handleChangeNumberOfColum}
          >
            <option value="10" defaultValue={props.pagination.items === "10"}>
              10
            </option>
            <option value="25" defaultValue={props.pagination.items === "25"}>
              25
            </option>
            <option value="50" defaultValue={props.pagination.items === "50"}>
              50
            </option>
            <option value="100" defaultValue={props.pagination.items === "100"}>
              100
            </option>
            <option value="150" defaultValue={props.pagination.items === "150"}>
              150
            </option>
          </select>
        </div>
      )}
      {props.pagination.total && (
        <div className="number-page">Total Items {props.pagination.total}</div>
      )}
      {props.pagination && (
        <div className="number-page">
          <Left prevPage={props.prevPage} page={props.pagination.page} />
          <select
            className="select-pages"
            onChange={props.paginate}
            value={props.pagination.page}
          >
            {paggesNumner.length > 0 &&
              paggesNumner.map((number) => {
                if (parseInt(props.pagination.page) === number) {
                  return (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  );
                } else {
                  return (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  );
                }
              })}
          </select>
          <Right nextPage={props.nextPage} page={props.pagination.page} />
        </div>
      )}
    </div>
  );
};
Pagination.defaultProps = {
  item: false,
};

export default Pagination;

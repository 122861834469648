import React from "react";

// components
import Card from "../card";
import Button from "../button";

//style
import "./index.scss";

const Notice = (props) => {
  return (
    <div className="notice-component-content">
      <Card
        icon={props.icon}
        title={props.name}
        modal
        closeModal={props.closeModal}
      >
        <div className="text-content">
          <h1>{props.title}</h1>
          <p>{props.textOne}</p>
          <p>{props.textTwo}</p>
        </div>
        <div className="button-content">
          <Button rounded onClick={(e) => props.closeModal()}>
            {props.button}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Notice;

import axios from "axios";
import { devbugData } from '../libs/methods'

export async function sendContact(contactform, recaptcha_response, dispatch) {
    dispatch({ type: 'SET_LOADING', loading: true })
    return new Promise((response, error) => {
        axios.post(`${devbugData()}/public/contact`, { contactform, recaptcha_response })
          .then(res => response(res))
          .catch(err => console.log(err))
          .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    })
}

import React from "react";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

//libs
import { getLang } from "../../../libs/cookies";

//components
import Button from "../../../components/button";
import Calendar from "../../../components/icons/calendar";

const Form = (props) => {
  registerLocale("es", es);
  return (
    <form className="form-content">
      <div className="form-detall-content vce">
        <div className="content-one">
          <div className="form-group">
            <label>
              <FormattedMessage id="global-other" />
            </label>
            <select
              className="form-control"
              defaultValue={props.bucket}
              onChange={(date) => props.handleChangeBucket(date)}
            >
              {props.buckets.map((bucket, index) => {
                return (
                  <option key={index} value={bucket}>
                    {bucket}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group calendar sp">
            <label>
              <FormattedMessage id="global-from" />
            </label>
            <div className="form-control">
              <DatePicker
                selected={props.date_ini}
                onChange={(date) => props.handleChangeDateIni(date)}
                locale={getLang()}
                dateFormat="dd/MM/yyyy"
                id="calendar-vce"
                popperPlacement="bottom"
              />
              <Calendar id="calendar-vce" />
            </div>
          </div>
          <div className="form-group calendar sp">
            <label>
              <FormattedMessage id="global-to" />
            </label>
            <div className="form-control">
              <DatePicker
                selected={props.date_end}
                onChange={(date) => props.handleChangeDateEnd(date)}
                locale={getLang()}
                dateFormat="dd/MM/yyyy"
                id="dateTo"
                name="dateTo"
                popperPlacement="bottom"
              />
              <Calendar id="dateTo" />
            </div>
          </div>
        </div>
        <div className="content-two">
          <div className="form-group">
            <label>IP</label>
            <input
              placeholder="8.8.8.8"
              type="text"
              name="ip"
              value={props.filters.ip}
              className="form-control"
              onChange={props.handleOnChange}
            />
          </div>
          <div className="form-group">
            <label>User</label>
            <input
              type="text"
              className="form-control"
              id="us"
              name="us"
              value={props.filters.us}
              placeholder="email@email.com"
              onChange={props.handleOnChange}
            />
          </div>
        </div>
        <div className="buttons-content">
          <Button
            rounded
            onClick={props.applyFilter}
            align="center"
            className="left"
          >
            <FormattedMessage id="global-apply-filters" />
          </Button>
          <Button
            rounded
            superBlue
            onClick={(e) => window.location.reload()}
            align="center"
          >
            Reset
          </Button>
        </div>
      </div>
    </form>
  );
};

export default injectIntl(withRouter(Form));

const initialState = {
  datos: {},
  tarifas: {},
  referidos: [],
  facturacion: {},
};

// auth reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_MY_DATA":
      return Object.assign({}, state, {
        datos: action.datos,
      });
    default:
      return state;
  }
};

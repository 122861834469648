import React from "react";

const Loading = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="154px"
      height="154px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="26.2465"
        fill="none"
        stroke="#00ef1d"
        strokeWidth="5"
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          values="0;29"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-0.704225352112676s"
        ></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-0.704225352112676s"
        ></animate>
      </circle>
      <circle
        cx="50"
        cy="50"
        r="13.0379"
        fill="none"
        stroke="#fff"
        strokeWidth="5"
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          values="0;29"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
        ></animate>
      </circle>
    </svg>
  );
};

export default Loading;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";

//actions
import { checkusertoken } from "../actions/user";

//libs
import { getAuth, removeAuth, setLang, getLang } from "./cookies";
import { langFormar } from "./methods";

//components
import Loading from "../components/loading";

const IsAuth = (props) => (WrappedComponent) => {
  return class IsAuth extends Component {
    constructor(props) {
      super(props);
      this.state = {
        auth: null,
      };
    }
    componentDidMount() {
      const auth = getAuth();
      this.setLangInit();
      if (!this.props.logged) {
        if (auth) {
          checkusertoken(auth.token)
            .then((response) => {
              const [res, code] = response;

              if (code !== 200) {
                removeAuth();
                this.setState({ auth: false });
              } else {
                this.props.dispatch({
                  type: "SET_AUTH",
                  auth: { auth: res.message, logged: true },
                });
                this.setState({ auth: true });
              }
            })
            .catch((e) => {
              removeAuth();
              this.setState({ auth: false });
              this.props.history.push("/login");
            });
        } else {
          this.setState({ auth: false });
        }
      } else {
        this.setState({ auth: true });
      }
    }

    setLangInit = () => {
      if (!getLang()) {
        setLang(langFormar(navigator.language));
      }
    };

    render() {
      if (this.state.auth === null) {
        return <Loading />;
      } else if (this.state.auth) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  };
};
export default IsAuth;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//libs
import { getLang, setLang } from "../../libs/cookies";

//actions
import { langFormar } from "../../libs/methods";

//translate
import translate from "../../i18n/translate";

//components
import Header from "../header";
import Footer from "../footer";
import Notice from "../notice";
import Done from "../icons/done";

//style
import "./index.scss";

class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    this.setLangInit();
  }
  closeModal = () => {
    this.props.history.push("/");
    this.props.dispatch({ type: "CLOSEALLMODAL" });
  };

  setLangInit = () => {
    if (!getLang()) {
      setLang(langFormar(navigator.language));
    }
  };

  render() {
    return (
      <div className="LoginLayout-container" id="layoutlogin">
        <ToastContainer autoClose={8000} />
        <Header showLinks={false} />
        <div className="content">{this.props.children}</div>
        <Footer />
        {this.props.modal.modalDone && (
          <Notice
            icon={<Done />}
            closeModal={this.closeModal}
            name={translate("notice-name-done")}
            button={translate("notice-button-acepted")}
            title={translate("notice-title-done")}
            textOne={translate("notice-text-one-done")}
            textTwo={translate("notice-text-two-done")}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal.modal,
  };
};

export default withRouter(connect(mapStateToProps)(LoginLayout));

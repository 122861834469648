import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

//translate
import translate from "../../../i18n/translate";

//libs
import { setLang, getLang } from "../../../libs/cookies";

import "./index.scss";

class Footer extends React.Component {
  changeLang = (lang) => {
    setLang(lang);
    window.location.reload();
  };
  render() {
    return (
      <footer className="footer mt-auto">
        <div className="container pb-5">
          <NavLink exact={true} activeClassName="active" to="/" role="button">
            <img
              src={`${process.env.REACT_APP_HOME_ROOT}/images/logo.png`}
              width="150"
              alt="logo"
            />
          </NavLink>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 align-self-center order-3 order-md-2">
              <div className="row">
                <div className="col-12 col-lg-12 copy-menu align-self-center">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="copy">
                        <span>
                          &copy; {new Date().getFullYear()} VidroSofware SL
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 ">
                      <div className="menufooter">
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          {" "}
                          {translate("public-footer-legal")}{" "}
                        </a>
                        <span className="sep d-none d-sm-inline-block">
                          {" "}
                          |{" "}
                        </span>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          {" "}
                          {translate("public-footer-privacy-policy")}{" "}
                        </a>
                        <span className="sep d-none d-sm-inline-block">
                          {" "}
                          |{" "}
                        </span>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          {" "}
                          {translate("public-footer-cookies-policy")}{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 footer-idiomas">
                      <span
                        className={classNames("en", {
                          active: getLang() === "en",
                        })}
                        onClick={(e) => this.changeLang("en")}
                      >
                        <a href="#en">{translate("footer-lang-en")}</a>
                      </span>
                      |
                      <span
                        className={classNames("es", {
                          active: getLang() === "es",
                        })}
                        onClick={(e) => this.changeLang("es")}
                      >
                        <a href="#es">{translate("footer-lang-es")}</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import axios from "axios";
export const request = (endPoint, dataToSend, method, auth) => {
  method = method.toUpperCase();
  let headers = { "Content-Type": "application/json" };
  if (auth) headers.Authorization = `Bearer ${auth}`;
  switch (method) {
    case "POST":
      return new Promise((resolve, reject) => {
        axios
          .post(endPoint, dataToSend, { headers: headers })
          .then(function (response) {
            resolve([response.data, 200]);
          })
          .catch((err) => {
            if (err.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              resolve([err.response.data, err.response.status]);
            } else if (err.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              reject("connection_refused");
              console.log(err.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", err.message);
              reject("network problem");
            }
          });
      });
    case "DELETE":
      return new Promise((resolve, reject) => {
        axios
          .delete(endPoint, {
            headers: { Authorization: `Bearer ${auth}` },
          })
          .then(function (response) {
            resolve([response.data, 200]);
          })
          .catch((error) => {
            resolve([error.response.data, error.response.status]);
          });
      });
    case "PUT":
      return new Promise((resolve, reject) => {
        axios
          .put(endPoint, dataToSend, { headers: headers })
          .then(function (response) {
            resolve([response.data, 200]);
          })
          .catch((error) => {
            resolve([error.response.data, error.response.status]);
          });
      });
    default:
      return new Promise((resolve, reject) => {
        axios
          .get(endPoint, {
            headers: { Authorization: `Bearer ${auth}` },
          })
          .then(function (response) {
            resolve([response.data, 200]);
          })
          .catch((err) => {
            if (err.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              resolve([err.response.data, err.response.status]);
            } else if (err.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              reject("connection_refused");
              console.log(err.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", err.message);
              reject("network problem");
            }
            //resolve([error.response.data, error.response.status]);
          });
      });
  }
};

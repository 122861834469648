import React from 'react'

const Search = (props) => {
  return (
    <svg onClick={e => props.search(e)} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
        <defs>
            <path id="searcha" d="M6.5 0C10.09 0 13 2.91 13 6.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5-1.5 1.5-5-5v-.79l-.27-.27C9.59 12.41 8.11 13 6.5 13 2.91 13 0 10.09 0 6.5S2.91 0 6.5 0zm0 2C4 2 2 4 2 6.5S4 11 6.5 11 11 9 11 6.5 9 2 6.5 2z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#F5F9FF" d="M220 90H1440V828H220z" transform="translate(-684 -187)"/>
            <g>
                <path fill="#FCFDFF" stroke="#C8D1DB" d="M.5 38.5V133c0 2.485 2.015 4.5 4.5 4.5h1170c2.485 0 4.5-2.015 4.5-4.5V38.5H.5z" transform="translate(-684 -187) translate(240 110)"/>
                <g transform="translate(-684 -187) translate(240 110) translate(180 68)">
                    <rect width="299" height="39" x=".5" y=".5" fill="#FFF" stroke="#4B5056" rx="5"/>
                    <g transform="translate(266 11)">
                        <use fill="#0592D8" fillRule="nonzero" xlinkHref="#searcha"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default Search;
import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";

//translate
import translate from "../../../i18n/translate";

//actions
import { getMembers, deleteMember, getMember } from "../../../actions/members";

//libs
import { getAuth } from "../../../libs/cookies";

//Component
import Card from "../../../components/card";
import Search from "../../../components/icons/search";
import Button from "../../../components/button";
import Loading from "../../../components/loading";
import Pagination from "../../../components/pagination";
import Herramienta from "../../../components/icons/herramienta";
import Update from "./update";
//style
import "./index.scss";
import "../index.scss";

class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      showAdd: false,
      member: null,
      page: null,
      filters: {
        name: "",
        pagination: {
          page: "1",
          items: "10",
        },
      },
    };
  }

  componentDidMount() {
    this.setState({ userId: getAuth().id });
    this.getMembers();
  }

  getMembers = () =>
    getMembers(this.props.dispatch, this.state.filters, getAuth().token);

  changeHandler = (e) => {
    this.setState({ search: e.target.value });
  };

  search = (e) => {
    e.preventDefault();
    const { filters, search } = this.state;
    filters.name = search;
    filters.pager.page = 1;
    this.getMembers();
  };
  showRegister = (e) => this.setState({ member: null, showUpdate: true });
  close = (reload = false) => {
    if (reload) this.getMembers();
    this.setState({ showAdd: false, showUpdate: false });
  };

  remove = (id) => {
    if (
      window.confirm(
        this.props.intl.formatMessage({ id: "herramientas-confirm-alert" })
      )
    ) {
      deleteMember(getAuth().token, id)
        .then((response) => {
          this.getMembers();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
  showUpdate = async (id) => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    await getMember(getAuth().token, id).then((res) => {
      this.setState({ member: res });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    });
    this.setState({ showUpdate: true });
  };
  handleOnChange = (e) => {
    this.setState({ search: e.target.value });
  };

  paginate = (e) => {
    const { filters } = this.state;
    filters.pagination.page = e.target.value;
    this.setState({ filters });
    this.getMembers();
  };

  handleChangeNumberOfColum = (e) => {
    const { filters } = this.state;
    filters.pagination.items = e.target.value;
    filters.pagination.page = 1;
    this.setState({ filters });
    this.getMembers();
  };

  nextPage = (page) => {
    const { filters } = this.state;
    page++;
    if (page <= this.props.members.pagination.pages) {
      filters.pagination.page = page;
      this.setState({ filters });
      this.getMembers();
    }
  };

  prevPage = (page) => {
    const { filters } = this.state;
    page--;
    if (page > 0) {
      filters.pagination.page = page;
      this.setState({ filters });
      this.getMembers();
    }
  };

  render() {
    const { members } = this.props;
    return (
      <React.Fragment>
        {(isEmpty(members) || this.props.loading) && <Loading />}
        {!isEmpty(members) && (
          <div className="herramientas-administrativas-content">
            {!this.state.showAdd && !this.state.showUpdate && (
              <React.Fragment>
                <Card
                  icon={<Herramienta color="#4b5056" />}
                  title={translate("herramientas-members-name")}
                >
                  <div className="banner-header-content">
                    <form
                      className={`search-wrapper ${this.props.class}`}
                      onSubmit={(e) => this.search(e)}
                    >
                      <div className="form-group input-content">
                        <input
                          type="text"
                          className="form-control"
                          name="search"
                          value={this.state.search}
                          onChange={this.handleOnChange}
                        />
                        <Search search={this.search} />
                      </div>
                    </form>
                    <Button superBlue onClick={(e) => this.showRegister(e)}>
                      {translate("herramientas-banner-button-add")}
                    </Button>
                  </div>
                </Card>
                <div className="banner-table-content">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">{translate("global-id")}</th>
                        <th scope="col">
                          {translate("herramientas-banner-table-company")}
                        </th>
                        <th scope="col">{translate("global-name")}</th>
                        <th scope="col">
                          {translate("herramientas-banner-table-email")}
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {members.data.length > 0 && (
                        <React.Fragment>
                          {members.data.map((item, index) => {
                            //let src = `${process.env.PUBLIC_URL}/flags/${item.pais.toLowerCase()}.png`
                            return (
                              <tr
                                key={index}
                                className={(index + 1) % 2 ? "impar" : "par"}
                              >
                                <td>{item.id}</td>
                                <td>{item.company}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>
                                  <div className="button-content">
                                    <Button
                                      blue
                                      onClick={(e) => this.showUpdate(item.id)}
                                    >
                                      {translate(
                                        "herramientas-banner-button-update"
                                      )}
                                    </Button>
                                  </div>
                                </td>
                                <td>
                                  {item.id !== this.state.userId && (
                                    <div className="button-content">
                                      <Button
                                        red
                                        onClick={(e) => this.remove(item.id)}
                                      >
                                        {translate(
                                          "herramientas-banner-button-remove"
                                        )}
                                      </Button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  handleChangeNumberOfColum={this.handleChangeNumberOfColum}
                  pagination={members.pagination}
                  paginate={this.paginate}
                  prevPage={this.prevPage}
                  nextPage={this.nextPage}
                  total={members.total}
                />
              </React.Fragment>
            )}
            {this.state.showUpdate && (
              <Update
                member={this.state.member}
                close={this.close}
                token={getAuth().token}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    loading: state.loading.loading,
    members: state.members.members,
  };
};

export default injectIntl(connect(mapStateToProps)(Members));

import { devbugData } from "../libs/methods";
import { request } from "../shared/api-service";
import axios from "axios";
export async function getBilling(datefrom, dateto, token) {
  let req = `${devbugData()}/billing/?`;
  if (datefrom) req = `${req}datefrom=${datefrom}`;

  return request(req, {}, "GET", token);
}

export async function generateBilling(token) {
  let req = `${devbugData()}/billing/generatebilling`;
  return request(req, {}, "GET", token);
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./index.scss";

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      violet,
      initial,
      superBlue,
      blue,
      gold,
      red,
      green,
      mint,
      rounded,
      minWidth,
      fullWidth,
      children,
      ...props
    } = this.props;
    return (
      <button
        type="button"
        {...props}
        className={classNames(
          "button",
          {
            "button--violet": this.props.violet,
            "button--initial": this.props.initial,
            "button--superBlue": this.props.superBlue,
            "button--blue": this.props.blue,
            "button--gold": this.props.gold,
            "button--green": this.props.green,
            "button--red": this.props.red,
            "button--mint": this.props.mint,
            "button--rounded": this.props.rounded,
            "button--min-width": this.props.minWidth,
            "button--full-width": this.props.fullWidth,
            "button--download": this.props.download,
            "button--download-small": this.props.downloadsmall,
          },
          this.props.className
        )}
      >
        {this.props.children}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  gold: PropTypes.bool,
  green: PropTypes.bool,
  mint: PropTypes.bool,
  rounded: PropTypes.bool,
  minWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  tag: PropTypes.oneOf(["a", "button"]),
  disabled: PropTypes.bool,
  align: PropTypes.string,
};

Button.defaultProps = {
  violet: false,
  initial: false,
  superBlue: false,
  blue: false,
  gold: false,
  green: false,
  mint: false,
  rounded: false,
  minWidth: true,
  fullWidth: false,
  disabled: false,
  align: "start",
  download: false,
  //downloadsmall: false
};

export default Button;

import { devbugData } from "../libs/methods";
import { request } from "../shared/api-service";
import axios from "axios";
export async function getVidromaps(filters, token) {
  return request(
    `${devbugData()}/vidromaps/map/?items=${filters.pagination.items}&page=${
      filters.pagination.page
    }`,
    {},
    "GET",
    token
  );
}
export async function addMap(mapData, geoproject, geoprojectdata, token) {
  return new Promise((resolve, reject) => {
    if (mapData.geoservice === 0) delete mapData.geoservice;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let formData = new FormData();
    if (geoproject) formData.append("geoproject", geoproject);
    if (geoprojectdata) formData.append("geoprojectdata", geoprojectdata);

    Object.keys(mapData).forEach((key) => {
      formData.append(key, mapData[key]);
    });

    axios({
      method: "post",
      url: `${devbugData()}/vidromaps/map/`,
      data: formData,
      headers: headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // Error 😨
        console.log(err);
        if (err.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          reject(err.response.data);
        } else if (err.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */

          reject({ error: "Error connecting to API" });

          //console.log(err.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          reject("network problem");
        }
      });
  });
}
export async function deleteMap(id, token) {
  return request(`${devbugData()}/vidromaps/map/${id}`, {}, "DELETE", token);
}
export async function getMap(id, token) {
  return request(`${devbugData()}/vidromaps/map/${id}`, {}, "GET", token);
}

export async function previewMap(id, token) {
  return request(`${devbugData()}/vidromaps/preview/${id}`, {}, "GET", token);
}

export async function updateMap(token, vidroMap) {
  return request(`${devbugData()}/vidromaps/map/`, vidroMap, "PUT", token);
}

export async function publishMap(token, id, published) {
  return request(
    `${devbugData()}/vidromaps/publish/`,
    { id: id, published: published },
    "PUT",
    token
  );
}

export async function updateFiles(id, geoproject, geoprojectdata, token) {
  return new Promise((resolve, reject) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let formData = new FormData();
    formData.append("id", id);
    if (geoproject) formData.append("geoproject", geoproject);
    if (geoprojectdata) formData.append("geoprojectdata", geoprojectdata);
    axios({
      method: "post",
      url: `${devbugData()}/vidromaps/updatefile/`,
      data: formData,
      headers: headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // Error 😨
        if (err.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          reject(err.response.data);
        } else if (err.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          reject({ error: "Error connecting to API" });
          console.error(err.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", err.message);
          reject("network problem");
        }
      });
  });
}

export async function editiontoken(id, name, token) {
  return request(
    `${devbugData()}/vidromaps/editiontoken/`,
    { id, name },
    "POST",
    token
  );
}

export async function downloadFile(file, token) {
  let req = `${devbugData()}/vidromaps/file/${file}`;
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios({
    url: req,
    method: "GET",
    responseType: "blob", // important
    headers,
  }).then((response) => {
    if (!window.navigator.msSaveOrOpenBlob) {
      // BLOB NAVIGATOR
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
    } else {
      // BLOB FOR EXPLORER 11
      const url = window.navigator.msSaveOrOpenBlob(
        new Blob([response.data]),
        file
      );
    }
  });
}

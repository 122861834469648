import cookie from "react-cookies";

export const setAuth = (auth) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save("auth", auth, {
    path: "/",
    expires: expires,
  });
};

export const setLang = (lang) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save("lang", lang, {
    path: "/",
    expires: expires,
  });
};

export const setDevBugCookie = (data) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save("devbug", data, {
    path: "/",
    expires: expires,
  });
};

export const setComponentCookie = (data) => {
  console.log(data);
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save("component", data, {
    path: "/",
    expires: expires,
  });
};
export const getComponentCookie = () => {
  const componenteUrl = cookie.load("component");
  if (componenteUrl === undefined) return false;
  return componenteUrl;
};
export const getAuth = () =>
  cookie.load("auth", true) !== undefined ? cookie.load("auth") : "";

export const getLang = () =>
  cookie.load("lang", true) !== undefined ? cookie.load("lang") : "es"; // If Lang Cookie not exists -> ES

export const getDevBugCookie = () => cookie.load("devbug", true);

export const removeAuth = () => cookie.remove("auth", { path: "/" });

import React from 'react'
import { withRouter } from 'react-router-dom'

//translate
import translate from '../../i18n/translate'

//componentd
import LoginLayout from '../../components/login-layout'

import Button from '../../components/button'

//style
import './index.scss'

const NotFound = (props) => {
    return (
        <LoginLayout>
            <div className="card-container">
                <div className="card">
                    <div className="card-header">
                        <Icon />
                        <h1>404</h1>
                    </div>
                    <div className="card-body">
                        <p>{translate('default-not-found')}</p>

                        <div className="button-content">
                            <Button rounded onClick={ e => props.history.push("/")} align="center">
                                {translate('statistics-form-button-return')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </LoginLayout>
    )
}

const Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
            <path fill="#4b5056" d="M18 9V4h2v5h-2zm0 4v-2h2v2h-2zM8 10c2.67 0 8 1.34 8 4v3H0v-3c0-2.66 5.33-4 8-4zm0-9c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 10.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V14c0-.64-3.13-2.1-6.1-2.1zm0-9c-1.16 0-2.1.94-2.1 2.1 0 1.16.94 2.1 2.1 2.1 1.16 0 2.1-.94 2.1-2.1 0-1.16-.94-2.1-2.1-2.1z"/>
        </svg>
    )
}

export default  withRouter(NotFound)

import React from 'react'

const Left = (props) => {
  return (
    <svg onClick={ e => props.prevPage(props.page)} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <defs>
          <path id="left__a" d="M1.41 0.58L6 5.17 10.59 0.58 12 2 6 8 0 2z"/>
      </defs>
      <g fill="none" fillRule="evenodd" transform="rotate(90 5 10)">
          <mask id="left__b" fill="#fff">
              <use xlinkHref="#left__a"/>
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#left__a"/>
          <g fill="#4B5056" mask="url(#left__b)">
              <path d="M0 0H22V22H0z" transform="translate(-5 -7)"/>
          </g>
      </g>
  </svg>

    )
}

export default Left;
import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import ReactJson from "react-json-view";
//translate
import translate from "../../../i18n/translate";

//actions
import { getLog } from "../../../actions/logs";

//libs
import { getAuth } from "../../../libs/cookies";

//Component
import IconHerramienta from "../../../components/icons/herramienta";
import Card from "../../../components/card";

// style
import "../index.scss";
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bucket: props.bucket,
      logId: props.logId,
      errorMessage: null,
      logdetail: {},
    };
  }

  componentDidMount() {
    this.setState({
      userId: getAuth().id,
      type: getAuth().type,
    });
    this.getLog();
  }

  getLog = async () => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const [res, code] = await getLog(
      this.state.bucket,
      this.state.logId,
      getAuth().token
    );
    if (code === 200) {
      this.setState({ logdetail: res.message });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };

  close = (reload = false) => {
    this.props.close();
    // if (reload) this.getVidromaps();
    //this.setState({ showAdd: false, currentMap: null, preview: null });
  };

  handleOnChange = (e) => {
    this.setState({ search: e.target.value });
  };

  fomatDate = (date) => {
    let dt = new Date(Date.parse(date));
    return `${dt.getMonth() + 1} - ${dt.getFullYear()}`;
  };

  render() {
    const { members } = this.props;

    return (
      <React.Fragment>
        {this.state.logdetail && (
          <Card
            icon={<IconHerramienta color="#4b5056" />}
            title={`Log - ${this.state.logdetail["fecha"]}`}
            modal
            closeModal={this.props.close}
          >
            <div>
              <ReactJson displayDataTypes={false} src={this.state.logdetail} />
            </div>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    loading: state.loading.loading,
  };
};

export default injectIntl(connect(mapStateToProps)(Detail));

import React, { Component } from "react";

//libs
import { devbugData } from "../../libs/methods";
import { setDevBugCookie, setComponentCookie } from "../../libs/cookies";

// components
import Card from "../card";
import Button from "../button";

//style
import "./index.scss";

class DevModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiUrl: "",
      componentUrl: "USE API",
    };
  }

  handleChangeSelected = (e) => this.setState({ apiUrl: e.target.value });
  handleOnChange = (e) => this.setState({ componentUrl: e.target.value });

  saveChange = () => {
    if (this.state.apiUrl === "") {
      setDevBugCookie(process.env.REACT_APP_API_URL);
    } else {
      setDevBugCookie(this.state.apiUrl);
    }
    if (this.state.componentURL !== "USE API") {
      setComponentCookie(this.state.componentUrl);
    }
    this.props.closeModal();
  };

  render() {
    return (
      <div className="devbug-content">
        <Card title="MODE DEBUGADOR" modal closeModal={this.props.closeModal}>
          <div className="alert alert-success" role="alert">
            API actual:{" "}
            {this.state.apiUrl === "" ? devbugData() : this.state.apiUrl}
          </div>
          <div className="form-group">
            <label>Seleccionar api url</label>
            <select
              className="form-control"
              onChange={this.handleChangeSelected}
            >
              <option value={process.env.REACT_APP_API_URL}>
                Según variable de entorno
              </option>
              <option value="https://api.vidrosoftware.com">Forzar API</option>
              <option value="https://devapi.vidrosoftware.com">
                Forzar DEVAPI
              </option>
            </select>
          </div>
          <div className="form-group">
            <label>Seleccionar componente url</label>
            <input
              type="text"
              className="form-control"
              name="componentUrl"
              value={this.state.componentUrl}
              onChange={this.handleOnChange}
            />
          </div>
          <div className="button-content">
            <Button rounded onClick={(e) => this.saveChange()}>
              Guardar
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default DevModal;

const initialState = {
  adminBanners: {},
  banners: {},
};

// auth reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_MEMBERS":
      return Object.assign({}, state, {
        members: action.members,
      });
    default:
      return state;
  }
};

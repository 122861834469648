import axios from "axios";
import { devbugData } from "../libs/methods";
import { request } from "../shared/api-service";

export async function userLogin(email, password) {
  return request(
    `${devbugData()}/memberletsgo`,
    {
      email: email,
      pwd: password,
    },
    "POST",
    null
  );
}

export async function adminLogin(email, password) {
  return request(
    `${devbugData()}/adminletsgo`,
    {
      email: email,
      pwd: password,
    },
    "POST",
    null
  );
}

export async function checkusertoken(token) {
  return request(`${devbugData()}/token`, {}, "GET", token);
}

export async function logout(token) {
  return request(`${devbugData()}/logout`, { token: token }, "POST", null);
}

export async function me(token) {
  return request(`${devbugData()}/me`, {}, "GET", token);
}

export async function sendEmailPassword(email) {
  return request(
    `${devbugData()}/members/requestpass`,
    {
      email: email,
    },
    "POST",
    null
  );
}

export async function recovery(new_password1, new_password2, token) {
  return request(
    `${devbugData()}/members/resetpassword`,
    {
      token: token,
      pwd1: new_password1,
      pwd2: new_password2,
    },
    "POST",
    null
  );
}

export async function changExpiredPassword(old_pwd, pwd, token) {
  return new Promise((response, error) => {
    axios
      .post(`${devbugData()}/changepassword`, {
        old_pwd,
        pwd,
        token,
      })
      .then((res) => {
        response(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });
}

import React from "react";
//import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const OtherGeoserviceForm = (props) => {
  const handleOnChange = (e) => {
    // const { value } = e.target;
    // props.setGeoservice(value);
  };
  return (
    <React.Fragment>
      {props.geoservice === "Other" && (
        <div className="form-group">
          <label>
            <FormattedMessage id="vidromaps-external_geo" />
          </label>
          <input
            type="text"
            className="form-control"
            name="external_geo"
            value={props.external_geo}
            onChange={handleOnChange}
          />
          <small id="passwordHelpBlock" className="form-text text-muted">
            <FormattedMessage id="vidromaps-external_geo-help" />
          </small>
        </div>
      )}
      {props.geoservice === "Other" && (
        <div className="form-group">
          <label>
            <FormattedMessage id="vidromaps-options" />
          </label>
          <textarea
            className="form-control"
            name="options"
            value={props.options}
            onChange={handleOnChange}
          ></textarea>
          <small id="passwordHelpBlock" className="form-text text-muted">
            <FormattedMessage id="vidromaps-options-help" />
          </small>
        </div>
      )}
    </React.Fragment>
  );
};
export default OtherGeoserviceForm;

import React from "react";

const Content = ({ cssClass, children }) => {
  return(
    <div className={`${cssClass} body-content`}>
      {children}
    </div>
  )
}
export default Content;

import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import store from "./store";

// libs
import { getLang } from "./libs/cookies";
import { langFormar } from "./libs/methods";

// Language
import messages from "./i18n";

// templated
// Public
import PublicHome from "./templates/public/home";
import Documentation from "./templates/documentation";
import PublicContact from "./templates/public/contact";
import PublicApiDocumentation from "./templates/public/api_documentation";
// Panel
import Login from "./templates/login";
import Mapas from "./templates/mapas";
import Administracion from "./templates/administracion";
import Billing from "./templates/billing";
import Logs from "./templates/logs";

import NotFound from "./templates/notFound";

// styles
import "./index.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.lang = langFormar(getLang() ? getLang() : navigator.language);
    console.log(process.env);
  }

  render() {
    return (
      <IntlProvider
        locale={this.lang}
        textComponent={Fragment}
        messages={messages[this.lang]}
      >
        <Provider store={store}>
          <BrowserRouter basename={`${process.env.REACT_APP_HOME_ROOT}`}>
            <Switch>
              <Route exact path={`/`} component={PublicHome} />
              <Route exact path={`/contact`} component={PublicContact} />
              <Route exact path="/documentation" component={Documentation} />
              <Route
                exact
                path="/api/documentation"
                component={PublicApiDocumentation}
              />
              <Route path={`/login`} component={Login} />
              <Route path="/email-recovery" component={Login} />
              <Route path="/recovery/:token" component={Login} />
              <Route exact path={`/maps`} component={Mapas} />
              <Route exact path="/billing" component={Billing} />
              <Route exact path="/tools/:section" component={Logs} />

              <Route
                path="/administracion/:section"
                component={Administracion}
              />

              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </IntlProvider>
    );
  }
}

export default App;

import React from "react";

//translate
import translate from "../../../i18n/translate";

//style
import "./index.scss";

const MainValuesSection = (props) => {
  return (
    <section className="values white-bg">
      <div className="container">
        <h2>{translate("public-main-values-title")}</h2>
        <p className="subtitle">{translate("public-main-values-subtitle")}</p>

        <div className="thumbs-wrapper row">
          <div className="col-10 offset-1 col-md-5 offset-md-1 thumb">
            <div
              className="icon animate"
              data-anim-type="bounceInLeft"
              data-anim-delay="200"
            >
              <picture>
                <img
                  src={`${process.env.REACT_APP_HOME_ROOT}/images/icon-effort.png`}
                  srcSet={`${process.env.REACT_APP_HOME_ROOT}/images/icon-effort.png 1x, ${process.env.REACT_APP_HOME_ROOT}/images/icon-effort@2x.png 2x, ${process.env.REACT_APP_HOME_ROOT}/images/icon-effort@3x.png 3x"`}
                  alt="A toda máquina"
                  className="img-fluid"
                />
              </picture>
            </div>
            <div className="text">
              <span className="thumb-title">
                {translate("public-main-values-full-speed")}
              </span>

              <span className="thumb-subtitle">
                {translate("public-main-values-text1")}
              </span>
            </div>
          </div>
          <div className="col-10 offset-1 col-md-5 offset-md-1 thumb">
            <div
              className="icon animate"
              data-anim-type="bounceInLeft"
              data-anim-delay="200"
            >
              <picture>
                <img
                  src={`${process.env.REACT_APP_HOME_ROOT}/images/icon-motivate.png`}
                  srcSet={`${process.env.REACT_APP_HOME_ROOT}/images/icon-motivate.png 1x, ${process.env.REACT_APP_HOME_ROOT}/images/icon-motivate@2x.png 2x, ${process.env.REACT_APP_HOME_ROOT}/images/icon-motivate@3x.png 3x`}
                  alt="Ultimas tecnoloogías"
                  className="img-fluid"
                />
              </picture>
            </div>
            <div className="text">
              <span className="thumb-title">
                {translate("public-main-values-latest-tech")}
              </span>

              <span className="thumb-subtitle">
                {translate("public-main-values-text3")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainValuesSection;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";

//translate
import translate from "../../i18n/translate";

//actions
import {
  userLogin,
  adminLogin,
  sendEmailPassword,
  recovery,
  me,
} from "../../actions/user";

//libs
import { setAuth, getAuth, removeAuth } from "../../libs/cookies";

//componentd
import LoginLayout from "../../components/login-layout";
import DataOfAccessIcon from "../../components/icons/dataOfAcces";
import DevBugModal from "../../components/devbug";
import Button from "../../components/button";

//style
import "./index.scss";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        password: "",
        repeatPassword: "",
        oldPassword: "",
      },
      error: false,
      errorEamil: null,
      reforcePassword: false,
      passwordExpired: false,
      passwordExpiredToken: "",
      errorRepeatPassword: false,
      errorOldPassword: false,
      errorParameter: false,
      errorRequest: false,
      errorPassworFormat: false,
      errorSamePassword: false,
      errorEmpty: "",
      showTextExpired: false,
      devbug: false,
      sendMail: false,
      errorMessage: null,
      errorCode: null,
    };
  }

  componentDidMount() {
    if (getAuth()) {
      this.props.history.push("/maps");
    }
  }

  getMyData = (auth) => {
    me(auth.token)
      .then(([meresult, code]) => {
        const userdata = {
          token: auth.token,
          username: meresult.message.name,
          id: meresult.message.id,
          email: meresult.message.email,
          type: auth.type,
        };
        this.props.dispatch({
          type: "SET_MY_DATA",
          datos: userdata,
        });
        setAuth(userdata);
        this.props.history.push("/maps");
        this.props.dispatch({
          type: "SET_AUTH",
          auth: { auth: auth, logged: true },
        });
      })
      .catch((e) => {
        removeAuth();
        this.setState({ auth: false });
        this.props.history.push("/login");
      });

    setAuth(auth);
  };

  loginAsAdmin = (email, password) => {
    return new Promise((resolve, reject) => {
      adminLogin(email, password).then((response) => {
        const [res, code] = response;
        resolve([res, code]);
      });
    });
  };
  loginAsMember = (email, password) => {
    return new Promise((resolve, reject) => {
      userLogin(email, password).then((response) => {
        const [res, code] = response;
        resolve([res, code]);
      });
    });
  };

  login = async () => {
    const { email, password } = this.state.user;
    const [responseMember, codeMember] = await this.loginAsMember(
      email,
      password
    );
    //TODO IMPROVE THIS LOGIC
    if (codeMember === 200) {
      this.loginOK(responseMember.message, "member");
      return;
    } else {
      const [responseAdmin, codeAdmin] = await this.loginAsAdmin(
        email,
        password
      );
      if (codeAdmin === 200) {
        this.loginOK(responseAdmin.message, "admin");
        return;
      }
      this.loginKo("not found", codeAdmin);
    }
  };

  sendEmail = async () => {
    if (!this.state.sendMail) {
      const [res, code] = await sendEmailPassword(this.state.user.email);
      if (code === 200) {
        this.setState({ errorEamil: false, sendMail: true });
      } else {
        this.setState({ errorEamil: true });
      }
    }
  };

  loginOK = (token, type) => {
    this.setState({ errorCode: null, errorMessage: null });
    const auth = {
      token,
      type,
    };
    this.getMyData(auth);
  };

  loginKo = (res, code) => {
    this.setState({ errorCode: code, errorMessage: res });
  };

  recoveryPassword = async () => {
    if (this.state.user.password === this.state.user.repeatPassword) {
      this.setState({ errorRepeatPassword: false });
      const [res, code] = await recovery(
        this.state.user.password,
        this.state.user.repeatPassword,
        this.props.match.params.token
      );
      if (code === 200) {
        this.props.history.push("/login");
      } else {
        this.setState({ error: true });
      }

      /* recovery(this.state.user.password, this.state.user.repeatPassword,this.props.match.params.token).then(
        (res) => {
          if (res.data.status !== "Failed") {
            this.props.history.push("/login");
          } else {
            this.setState({ error: true });
          }
        }
      );*/
    } else {
      this.setState({ errorRepeatPassword: true });
    }
  };

  formPassword = (password) =>
    password.length < 8 || !/[0-9]/.test(password) || !/[A-Z]/.test(password);

  resetErrors = () => {
    this.setState({
      errorOldPassword: false,
      errorParameter: false,
      errorRequest: false,
      errorSamePassword: false,
      errorPassworFormat: false,
      showTextExpired: false,
    });
  };

  handleOnChange = (e) => {
    const { user } = this.state;
    const { value, name } = e.target;
    if (e.target.value === "devbug") {
      e.target.value = "";
      this.setState({ devbug: true });
    } else {
      this.setState({ devbug: false });
    }
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
      error: false,
    });
  };

  closeModal = () => this.setState({ devbug: false });

  render() {
    return (
      <LoginLayout>
        {this.state.devbug && <DevBugModal closeModal={this.closeModal} />}
        <div className="card-container">
          {this.props.match.path === "/login" &&
            !this.state.reforcePassword &&
            !this.state.passwordExpired && (
              <div className="card">
                <div className="card-header">
                  <DataOfAccessIcon />
                  <h1>{translate("login-title")}</h1>
                </div>
                <div className="card-body">
                  {this.state.error && (
                    <div className="alert alert-danger" role="alert">
                      {translate("login-error")}
                    </div>
                  )}
                  <ErrorMessage
                    errorCode={this.state.errorCode}
                    errorMessage={this.state.errorMessage}
                  />
                  <form>
                    <div className="form-group">
                      <label>{translate("login-user")}</label>
                      <input
                        type="email"
                        name="email"
                        className={classNames("form-control", {
                          "error-input": this.state.error,
                        })}
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>{translate("login-password")}</label>
                      <input
                        type="password"
                        name="password"
                        className={classNames("form-control", {
                          "error-input": this.state.error,
                        })}
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div className="recovery-remember-content">
                      <span>
                        {translate("login-rebember")} <input type="checkbox" />
                      </span>
                      <span
                        className="recovery-password"
                        onClick={(e) =>
                          this.props.history.push("/email-recovery")
                        }
                      >
                        {translate("login-recovery")}
                      </span>
                    </div>
                    <div className="form-group button-content">
                      <Button
                        rounded
                        onClick={(e) => this.login()}
                        align="center"
                      >
                        {translate("login-button-enter")}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          {(this.props.match.path === "/email-recovery" ||
            this.state.reforcePassword) && (
            <div className="card">
              <div className="card-header">
                <DataOfAccessIcon />
                <h1>{translate("login-recovery-title")}</h1>
              </div>
              <div className="card-body">
                {this.state.errorEamil === true && (
                  <div className="alert alert-danger" role="alert">
                    {translate("login-recovery-email-error")}
                  </div>
                )}
                {this.state.reforcePassword && (
                  <div className="alert alert-danger" role="alert">
                    {translate("login-recovery-email-error-rest")}
                  </div>
                )}
                {this.state.errorEamil === false && (
                  <div className="alert alert-success" role="alert">
                    {translate("login-recovery-email-succes")}
                  </div>
                )}
                {!this.state.reforcePassword ? (
                  <form>
                    <div className="form-group">
                      <label> {translate("login-recovery-email-label")}</label>
                      <input
                        type="email"
                        name="email"
                        className={classNames("form-control", {
                          "error-input": this.state.error,
                        })}
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div className="form-group button-content">
                      <Button
                        rounded
                        onClick={(e) => this.sendEmail()}
                        align="center"
                      >
                        {translate("global-send")}
                      </Button>
                    </div>
                    <div className="text-suport-content">
                      <p>{translate("login-recovery-support")}</p>
                      <a href="mailto:contacto@vidrosoftware.com">
                        contacto@vidrosoftware.com
                      </a>
                    </div>
                  </form>
                ) : (
                  <div className="form-group button-content">
                    <Button
                      rounded
                      onClick={(e) => this.openChat()}
                      align="center"
                    >
                      {translate("login-chat-assistent")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
          {(this.props.match.path === "/recovery/:token" ||
            this.state.passwordExpired) && (
            <div className="card">
              <div className="card-header">
                <DataOfAccessIcon />
                <h1>{translate("login-recovery-title")}</h1>
              </div>
              <div className="card-body">
                {this.state.showTextExpired && this.state.passwordExpired && (
                  <div className="alert alert-danger" role="alert">
                    {translate("login-password-expired-text")}
                  </div>
                )}
                {this.state.error && (
                  <div className="alert alert-danger" role="alert">
                    {translate("login-recovery-error")}
                  </div>
                )}
                {this.state.errorRepeatPassword && (
                  <div className="alert alert-danger" role="alert">
                    {translate("login-recovery-error-password")}
                  </div>
                )}
                {this.state.errorParameter && (
                  <div className="alert alert-danger" role="alert">
                    {translate(
                      "herramientas-affiliate-error-request-parameter"
                    )}
                  </div>
                )}
                {this.state.errorRequest && (
                  <div className="alert alert-danger" role="alert">
                    {translate(
                      "herramientas-affiliate-change-password-error-request"
                    )}
                  </div>
                )}
                {this.state.errorPassworFormat && (
                  <div className="alert alert-danger" role="alert">
                    {translate(
                      "herramientas-affiliate-change-password-error-format"
                    )}
                  </div>
                )}
                {this.state.errorSamePassword && (
                  <div className="alert alert-danger" role="alert">
                    {translate(
                      "herramientas-affiliate-change-password-error-same"
                    )}
                  </div>
                )}
                {this.state.errorOldPassword && (
                  <div className="alert alert-danger" role="alert">
                    {translate(
                      "herramientas-affiliate-error-request-old-password"
                    )}
                  </div>
                )}
                <form>
                  {this.state.passwordExpired && (
                    <div className="form-group">
                      <label>
                        {translate("login-recovery-label-two-old-pwd")}
                      </label>
                      <input
                        type="password"
                        name="oldPassword"
                        className={classNames("form-control", {
                          "error-input":
                            this.state.error || this.state.errorPassworFormat,
                        })}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label>{translate("login-recovery-label-one")}</label>
                    <input
                      type="password"
                      name="password"
                      className={classNames("form-control", {
                        "error-input":
                          this.state.error || this.state.errorPassworFormat,
                      })}
                      onChange={this.handleOnChange}
                    />
                  </div>
                  {!this.state.passwordExpired && (
                    <div className="form-group">
                      <label>{translate("login-recovery-label-two")}</label>
                      <input
                        type="password"
                        name="repeatPassword"
                        className={classNames("form-control", {
                          "error-input": this.state.errorRepeatPassword,
                        })}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  )}
                  <div className="form-group button-content">
                    <Button
                      rounded
                      onClick={(e) =>
                        this.state.passwordExpired
                          ? this.changExpiredPassword()
                          : this.recoveryPassword()
                      }
                      align="center"
                    >
                      {translate("login-recovery-button")}
                    </Button>
                  </div>
                  <div className="text-suport-content">
                    <p>{translate("login-recovery-support")}</p>
                    <a href="mailto:contacto@vidrosoftware.com">
                      contacto@vidrosoftware.com
                    </a>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </LoginLayout>
    );
  }
}

const ErrorMessage = ({ errorCode, errorMessage }) => {
  if (!errorCode) return null;
  return (
    <div className="alert alert-danger" role="alert">
      {errorCode === 400
        ? translate("login-error-no-data")
        : errorCode === 401
        ? errorMessage
        : errorCode === 403
        ? translate("login-error")
        : errorCode === 404
        ? translate("login-error")
        : errorCode === 500
        ? "Ha ocurrido un error. Inténtelo más tarde."
        : `Error ${errorCode}`}
    </div>
  );
};
export default withRouter(connect()(Login));

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { ToastContainer } from "react-toastify";

//actions
import { logout } from "../../actions/user";

//libs
import { getAuth, removeAuth } from "../../libs/cookies";

//Components
import Header from "../header";
import Footer from "../footer";
import isAuth from "../../libs/isAuth";
import Sidebar from "../sidebar";

import ShowImg from "../showImg";

//style
import "./index.scss";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0,
      footerHeight: 0,
      showMenu: true,
      showUserMenu: true,
    };
  }

  componentDidMount() {
    this.setState({
      headerHeight: document.getElementById("header").offsetHeight,
      footerHeight: document.getElementById("footer").offsetHeight,
    });
  }

  showSidebar = () => {
    if (!this.state.showUserMenu) {
      document.getElementById("menu-user").classList.remove("active");
      this.setState({ showUserMenu: !this.state.showUserMenu });
    }
    if (this.state.showMenu) {
      document.getElementById("sideba-movil").classList.add("active");
    } else {
      document.getElementById("sideba-movil").classList.remove("active");
    }
    this.setState({ showMenu: !this.state.showMenu });
  };

  logoutUser = () => {
    if (window.confirm("¿Cerrar sesión?")) {
      logout(getAuth().token).then((res) => {
        if (res.status !== "Failed") {
          removeAuth();
          this.props.history.push("/login");
        } else {
          this.setState({ errorEamil: true });
        }
      });
    }
  };

  showUser = () => {
    if (!this.state.showMenu) {
      document.getElementById("sideba-movil").classList.remove("active");
      this.setState({ showMenu: !this.state.showMenu });
    }
    if (this.state.showUserMenu) {
      document.getElementById("menu-user").classList.add("active");
    } else {
      document.getElementById("menu-user").classList.remove("active");
    }
    this.setState({ showUserMenu: !this.state.showUserMenu });
  };

  closeModal = () => {
    this.props.dispatch({ type: "CLOSEALLMODAL" });
  };

  render() {
    return (
      <div className="Layout-container">
        <ToastContainer autoClose={6000} />
        <Header
          showLinks={true}
          showSidebar={this.showSidebar}
          showMenu={this.state.showMenu}
          showUserMenu={this.state.showUserMenu}
          showUser={this.showUser}
          username={!isEmpty(this.props.auth) && this.props.auth.username}
          admin={!isEmpty(this.props.auth) && this.props.auth.admin}
          logoutUser={this.logoutUser}
        />
        <div style={{ height: `${this.state.headerHeight}px` }}></div>
        <div className="layout-content">
          <div id="sideba-movil" className="sidebar-container-movil">
            <Sidebar showSidebar={this.showSidebar} />
          </div>
          <div className="hidden-sidebar" />
          <div id="sidebar" className="sidebar-container">
            {" "}
            <Sidebar showSidebar={() => {}} />
          </div>
          <div className="content">{this.props.children}</div>
        </div>
        <Footer />
        {this.props.modal.showImg && (
          <ShowImg img={this.props.modalImg} closeModal={this.closeModal} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    logged: state.auth.logged,
    modal: state.modal.modal,
    userdata: state.administracion.datos,
    modalImg: state.modal.img,
  };
};

export default withRouter(connect(mapStateToProps)(isAuth()(Layout)));

import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

// Translate
import translate from "../../../i18n/translate";

class Menu extends React.Component {
  render() {
    return (
      <header className="header">
        <Container>
          <Navbar className="menu navbar-custom" expand="lg">
            <div className="logo-wrapper">
              <NavLink
                exact={true}
                activeClassName="active"
                to="/"
                role="button"
              >
                <img
                  src={`${process.env.REACT_APP_HOME_ROOT}/images/logo.png`}
                  width="200"
                  alt="logo"
                />
              </NavLink>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <NavLink
                  className="btn btn-transparent"
                  to="/login"
                  role="button"
                >
                  {translate("public-menu-login")}
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
    );
  }
}

export default Menu;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Close extends Component {

    constructor (props) {
      super(props)
          this.state = {}
    }
    render() {
        const {
          cursorDefault,
          cursorPointer,
          ...props
        } = this.props
        return (
            <svg {...props} className={classNames('icon', {
                  'cursorDefault': this.props.cursorDefault,
                  'cursorPointer': this.props.cursorPointer,
                }, this.props.className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill={props.color} transform="translate(5 5)" id="a" d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"/>
            </svg>
        )
    }
}

Close.propTypes = {
  cursorDefault: PropTypes.bool,
  cursorPointer: PropTypes.bool,
};

Close.defaultProps = {
  cursorDefault: false,
  cursorPointer: false,
};

export default Close;

import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
//translate
import translate from "../../../i18n/translate";

//actions
import { getBilling, generateBilling } from "../../../actions/billing";

//libs
import { getAuth } from "../../../libs/cookies";
import { dateFormat } from "../../../libs/methods";

//Component
import Card from "../../../components/card";
import LocalPay from "../../../components/icons/localpay";
import Button from "../../../components/button";
import Loading from "../../../components/loading";
// style
import "../index.scss";
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      userId: null,
      date_ini: new Date(),
      date_end: new Date(),
      billing: null,
      errorCode: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    let today = new Date();
    let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.setState({
      userId: getAuth().id,
      type: getAuth().type,

      date_ini: dateFormat(firstDayOfMonth),
      date_end: dateFormat(today),
    });

    this.getBilling();
  }

  getBilling = async () => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const [res, code] = await getBilling(null, null, getAuth().token);
    this.props.dispatch({ type: "SET_LOADING", loading: false });
    if (code === 200) {
      this.setState({ billing: res.message });
    } else {
      this.setState({ errorCode: code, errorMessage: res.message });
    }
  };

  changeHandler = (e) => {
    this.setState({ search: e.target.value });
  };

  generateBillingHandler = async (e) => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const [res, code] = await generateBilling(getAuth().token);
    this.props.dispatch({ type: "SET_LOADING", loading: false });
    if (code === 200) {
      this.props.dispatch({ type: "SET_LOADING", loading: true });
      const [res2, code2] = await getBilling(null, null, getAuth().token);
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      if (code2 === 200) {
        this.setState({ billing: res2.message });
      } else {
        this.setState({ errorCode: code2, errorMessage: res2.message });
      }
    } else {
      this.setState({ errorCode: code, errorMessage: res.message });
    }
  };

  close = (reload = false) => {
    if (reload) this.getVidromaps();
    this.setState({ showAdd: false, currentMap: null, preview: null });
  };

  handleOnChange = (e) => {
    this.setState({ search: e.target.value });
  };
  resetError = () => {
    this.setState({
      errorCode: null,
      errorMessage: null,
    });
  };

  handleChangeDate = (date) => {
    const { filters } = this.state;
    this.setState({
      date: date ? dateFormat(date) : "",
    });
  };

  fomatDate = (date) => {
    let dt = new Date(Date.parse(date));
    return `${dt.getMonth() + 1} - ${dt.getFullYear()}`;
  };
  getTotal = (item) => {
    return item.maps + item.maintenance;
  };
  render() {
    const { members } = this.props;
    registerLocale("es", es);
    return (
      <React.Fragment>
        {this.props.loading && <Loading />}
        <Card icon={<LocalPay color="#4b5056" />} title={"Billing"}>
          <div className="herramientas-administrativas-content">
            {this.state.type === "admin" && (
              <div className="banner-header-content">
                <Button
                  superBlue
                  onClick={(e) => this.generateBillingHandler()}
                >
                  {translate("billing-generate-billing")}
                </Button>
              </div>
            )}

            {this.state.errorCode && (
              <div className="banner-table-content">
                <ErrorMessage
                  errorCode={this.state.errorCode}
                  errorMessage={this.state.errorMessage}
                />
              </div>
            )}

            {!isEmpty(this.state.billing) && (
              <React.Fragment>
                <div className="banner-table-content">
                  <table className="table">
                    <thead>
                      <tr>
                        {this.state.type === "admin" && (
                          <th scope="col">
                            {translate("administration-data-company")}
                          </th>
                        )}
                        <th scope="col">
                          {translate("administration-referred-mont")}
                        </th>
                        <th scope="col">
                          {translate("administration-maintenance")}
                        </th>
                        <th scope="col">{translate("sidebar-maps")}</th>
                        <th scope="col">
                          <b>{translate("global-total")}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.billing.data.length > 0 && (
                        <React.Fragment>
                          {this.state.billing.data.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className={(index + 1) % 2 ? "impar" : "par"}
                              >
                                {this.state.type === "admin" && (
                                  <td>{item.name}</td>
                                )}
                                <td>{this.fomatDate(item.register_date)}</td>
                                <td>{item.maintenance}€</td>
                                <td>{item.maps}€</td>
                                <td>
                                  <b>{this.getTotal(item)}</b>€
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            )}
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    loading: state.loading.loading,
  };
};
const ErrorMessage = ({ errorCode, errorMessage }) => {
  if (!errorCode) return null;
  return (
    <div className="alert alert-danger" role="alert">
      {errorCode === 400
        ? translate("global-error-bad-request")
        : errorCode === 401
        ? errorMessage
        : errorCode === 402
        ? errorMessage
        : errorCode === 403
        ? translate("global-unauthorized")
        : errorCode === 404
        ? translate("global-no-data")
        : errorCode === 500
        ? "Ha ocurrido un error. Inténtelo más tarde."
        : `Error ${errorCode}`}
    </div>
  );
};
export default injectIntl(connect(mapStateToProps)(List));

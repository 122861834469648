import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
//translate
import translate from "../../../i18n/translate";

// components
import Card from "../../../components/card";

import IconSettings from "../../../components/icons/settings";
import "./index.scss";
class Doc extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  handleOnChange = (e) => {};

  render() {
    return (
      <React.Fragment>
        <div className="data-content">
          <Card
            icon={<IconSettings />}
            title={translate("global-documentation")}
          >
            <h1 className="docTitle">{translate("documentation-title")}</h1>
            <div className="docContent">
              {translate("documentation-description1")}
              <br></br>
              <br></br>

              <ul>
                <li>{translate("documentation-description2")}</li>
                <li>{translate("documentation-description3")}</li>
                <li>{translate("documentation-description4")}</li>
              </ul>

              {translate("documentation-description5")}

              <br></br>
              <br></br>
              {translate("documentation-description6")}
            </div>
            <div className="docContent">
              <img
                src="images/basicflow.png"
                className="logo"
                width="500px"
                alt="Vidrosoftware SL"
              />
            </div>
            <div className="form-group">
              <NavLink
                to="/api/documentation"
                role="button"
                target="_blank"
                onClick={this.props.showSidebar}
              >
                {translate("global-api_documentation")}
              </NavLink>
            </div>
            <div className="form-group">
              <a
                href="https://github.com/Vidro-Software-SL/maphandler"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate("global-js_documentation")}
              </a>
            </div>
            <h1 className="docTitle">{translate("documentation-issue")}</h1>
            <div className="docContent">
              <h3 className="docTitle">
                {" "}
                {translate("documentation-issue-logo")}
              </h3>
              {translate("documentation-issue-logo-desc")}

              <br></br>
              <br></br>

              <pre>
                Header set Access-Control-Allow-Origin "*" Header add<br></br>
                Cross-Origin-Resource-Policy: "cross-origin" Header add<br></br>
                Cross-Origin-Embedder-Policy: "require-corp"<br></br>
              </pre>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Doc);

import React, { Component } from "react";

//translate
import translate from "../../../i18n/translate";

import { withRouter } from "react-router-dom";

import Layout from "../../../components/public-layout";

import MainValuesSection from "../MainValuesSection";

import "./index.scss";

class Home extends Component {
  render() {
    return (
      <Layout cssClass="homepage">
        <section className="main">
          <div className="main-content">
            <h1>{translate("public-home-main-title")} </h1>
          </div>
        </section>
        <section className="maxim white-bg">
          <h2>
            {translate("public-home-maximize-income")} <br />
            {translate("public-home-with-ole")}
          </h2>
          <p className="subtitle">{translate("public-home-subtitle")}</p>
          <div className="boxes-wrapper">
            <div className="external-box">
              <div
                className="box animate"
                data-anim-type="bounceIn"
                data-anim-delay="200"
              >
                <span className="top smallest">
                  {translate("public-home-tech")}
                </span>
                <span className="top">100%</span>
                <span className="sub">web</span>
              </div>
            </div>
            <div className="external-box">
              <div
                className="box animate"
                data-anim-type="bounceIn"
                data-anim-delay="200"
              >
                <span className="top smallest">
                  <br />
                </span>
                <span className="top">€</span>
                <span className="sub">{translate("public-home-pay")}</span>
              </div>
            </div>
          </div>
        </section>

        <MainValuesSection />
      </Layout>
    );
  }
}

export default withRouter(Home);

import React, { Component } from "react";
import Map from "../icons/map";
import Card from "../card";
import Button from "../button";
import { FormattedMessage } from "react-intl";
import "./index.scss";
import { getComponentCookie } from "../../libs/cookies";
class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iframe: this.props.data ? this.props.data.iframe : null,
      sessionToken: this.props.data ? this.props.data.sessionToken : "",
      published: this.props.data ? this.props.data.published : 0,
      share: `<iframe id="map-frame" name="map-frame" width="100%" height="600" src="${this.props.data.iframe}?sessionToken=${this.props.data.sessionToken}"></iframe>`,
    };
  }
  componentDidMount() {
    const overrideComponentUrl = getComponentCookie();
    if (overrideComponentUrl) {
      this.setState({
        iframe: overrideComponentUrl,
        share: `<iframe id="map-frame" name="map-frame" width="100%" height="600" src="${overrideComponentUrl}?sessionToken=${this.props.data.sessionToken}"></iframe>`,
      });
    }
  }
  handleOnChange = (e) => {};

  render() {
    return (
      <React.Fragment>
        <div className="herramientas-administrativas-content">
          <Card
            icon={<Map />}
            title="Vidromaps"
            modal
            closeModal={this.props.close}
          >
            <div className="banner-body">
              <iframe
                id="map-frame"
                name="map-frame"
                width="100%"
                height="600"
                src={`${this.state.iframe}?sessionToken=${this.state.sessionToken}`}
              ></iframe>
              {this.props.data.published === 1 && (
                <div>
                  <h3>
                    <FormattedMessage id="herramientas-preview-code" />
                  </h3>
                  <textarea
                    disabled
                    value={this.state.share}
                    onChange={this.handleOnChange}
                  ></textarea>
                </div>
              )}
            </div>{" "}
            <div className="button-content">
              {this.props.data.published === 0 && (
                <Button
                  rounded
                  onClick={(e) => this.props.publish(this.props.id, 1)}
                  green
                >
                  <FormattedMessage id="global-publish" />
                </Button>
              )}
              <Button rounded onClick={(e) => this.props.close(true)} red>
                <FormattedMessage id="global-close" />
              </Button>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default Preview;

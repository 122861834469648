import React, { Component } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { load as loadRecaptcha } from "recaptcha-v3";

//translate
import translate from "../../../i18n/translate";

import { sendContact } from "../../../actions/public";

import Button from "../../../components/button";
import NotificationError from "../../../components/icons/notificationError";
import NotificationSucess from "../../../components/icons/notificationSucces";
import Loading from "../../../components/loading";

import Layout from "../../../components/public-layout";

import "./index.scss";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        name: "",
        email: "",
        comments: "",
        communications: false,
        privacy: false,
      },
      // Recaptcha:
      recaptcha_validating: false,
      recaptcha_response: "",
      error: {
        name: false,
        email: false,
        comments: false,
        privacy: false,
        recaptcha: false,
      },
    };
  }

  send = () => {
    const { contact, error } = this.state;

    const validMail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (contact.name === "") {
      this.setState({ error: { ...error, name: true } });
      toast.error(
        <span>
          <NotificationError color="#ffffff" />
          {translate("support-contact-data")}
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "notif error-color",
        }
      );
    } else if (contact.email === "" || !validMail.test(contact.email)) {
      this.setState({ error: { ...error, email: true, name: false } });
      toast.error(
        <span>
          <NotificationError color="#ffffff" />
          {translate("support-contact-data")}
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "notif error-color",
        }
      );
    } else if (contact.comments === "") {
      this.setState({
        error: { ...error, comments: true, email: false, name: false },
      });
      toast.error(
        <span>
          <NotificationError color="#ffffff" />
          {translate("support-contact-data")}
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "notif error-color",
        }
      );
    } else if (contact.privacy === false) {
      this.setState({
        error: {
          ...error,
          comments: false,
          email: false,
          name: false,
          privacy: true,
        },
      });
      toast.error(
        <span>
          <NotificationError color="#ffffff" />
          {translate("support-contact-data")}
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          className: "notif error-color",
        }
      );
    } else {
      // RECAPTCHA CHECKING
      this.setState({
        recaptcha_validating: true,
      });
      loadRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY, {
        autoHideBadge: true,
      })
        .then((recaptcha) => {
          this.recaptcha = recaptcha;
          recaptcha.execute("action").then((token) => {
            this.setState({
              recaptcha_response: token,
              recaptcha_validating: false,
            });
            //SEND CONTACT FORM
            sendContact(
              contact,
              this.state.recaptcha_response,
              this.props.dispatch
            ).then((res) => {
              if (res.data.status !== "Failed") {
                this.setState({
                  error: {
                    ...error,
                    comments: false,
                    email: false,
                    name: false,
                    privacy: false,
                    recaptcha: false,
                  },
                  contact: {
                    name: "",
                    email: "",
                    comments: "",
                    privacy: false,
                    communications: false,
                  },
                });
                toast.success(
                  <span>
                    <NotificationSucess color="#ffffff" />
                    {translate("support-contact-done")}
                  </span>,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    className: "notif succes-color",
                  }
                );
              } else {
                toast.error(
                  <span>
                    <NotificationError color="#ffffff" />
                    {translate("support-contact-error-send")}
                  </span>,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    className: "notif error-color",
                  }
                );
              }
            });
          });
        })
        .catch((error) => {
          toast.error(
            <span>
              <NotificationError color="#ffffff" />
              {translate("support-contact-error-send")}
            </span>,
            {
              position: toast.POSITION.TOP_CENTER,
              className: "notif error-color",
            }
          );
          console.error(error);
        });
      // END RECAPTCHA CHECKING
    }
  };

  handleOnChange = (e) => {
    const { contact } = this.state;
    const { value, name } = e.target;
    this.setState({
      contact: {
        ...contact,
        [name]: value,
      },
    });
  };

  handleChecked = (e) => {
    const { contact } = this.state;
    if (e.target.name === "privacy") {
      this.setState({
        contact: {
          ...contact,
          privacy: contact.privacy !== false ? false : true,
        },
      });
    } else {
      this.setState({
        contact: {
          ...contact,
          communications: contact.communications !== false ? false : true,
        },
      });
    }
  };

  render() {
    return (
      <Layout css="contact">
        {this.props.loading && <Loading />}
        <section className="main">
          <div className="main-content">
            <div className="container"></div>
          </div>
        </section>
        <section>
          <div className="landing-sample white-bg container">
            <div className="content-wrapper">
              <div className="sample-header clearfix"></div>

              <div>
                <h2>{translate("public-contact-title")}</h2>
                <p className="subtitle">
                  {translate("public-contact-subtitle")}
                </p>
                <form className="form-horizontal">
                  <div className="form-group row required">
                    <label
                      for="user"
                      className="col-sm-2 col-form-label col-form-label-sm"
                    >
                      {translate("public-contact-form-contact")}
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        className={classNames("form-control", {
                          "error-input": this.state.error.name,
                        })}
                        name="name"
                        value={this.state.contact.name}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label
                      for="email"
                      className="col-sm-2 col-form-label col-form-label-sm"
                    >
                      {translate("public-contact-form-email")}
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-sm"
                        className={classNames("form-control", {
                          "error-input": this.state.error.email,
                        })}
                        value={this.state.contact.email}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row required">
                    <label
                      for="comments"
                      className="col-sm-2 col-form-label col-form-label-sm"
                    >
                      {translate("public-contact-form-comments")}
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        name="comments"
                        className="form-control form-control-sm"
                        rows="3"
                        className={classNames("form-control", {
                          "error-input": this.state.error.message,
                        })}
                        value={this.state.contact.comments}
                        onChange={this.handleOnChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10 offset-sm-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="privacy"
                          id="privacy"
                          checked={this.state.contact.privacy !== false}
                          onChange={this.handleChecked}
                        />
                        <label className="form-check-label" for="privacy">
                          {translate("public-contact-privacy-policy1")}
                          <a
                            href="https://legal.promocionesweb.com/condiciones.php?id_pagina=18"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {translate("public-contact-privacy-policy2")}
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10 offset-sm-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="communications"
                          id="communications"
                          checked={this.state.contact.communications !== false}
                          onChange={this.handleChecked}
                        />
                        <label
                          className="form-check-label"
                          for="communications"
                        >
                          {translate("public-contact-commercial-comm")}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10 offset-sm-2">
                      <span className="recaptcha-advice">
                        {translate("public-contact-protect-google")}{" "}
                        <a
                          href="https://policies.google.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translate("public-contact-protect-google-privacy")}
                        </a>{" "}
                        {translate("public-contact-protect-and")}{" "}
                        <a
                          href="https://policies.google.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translate("public-contact-protect-google-term")}
                        </a>
                        .
                      </span>
                    </div>
                  </div>

                  <div className="btn-wrapper text-center">
                    <Button rounded onClick={(e) => this.send()} align="center">
                      {translate("public-lets-go")}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.loading,
  };
};

export default connect(mapStateToProps)(Contact);

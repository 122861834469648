import React from "react";

const Logo = (props) => {
  return (
    <img
      src={`${process.env.REACT_APP_HOME_ROOT}/images/logo.png`}
      height="63"
      className="logo"
      alt="Vidrosoftware SL"
      onClick={(e) => props.backHome()}
    />
  );
};

export default Logo;

import React from "react";
import { FormattedMessage } from "react-intl";
const LogoForm = (props) => {
  const handleOnChange = (e) => {
    const { value } = e.target;
    props.setLogo(value);
  };
  return (
    <div className="form-group">
      <label>Logo</label>
      <input
        type="text"
        className="form-control"
        name="logo"
        placeholder="https://www.vidrosoftware.com/vidro.jpg"
        value={props.logo}
        onChange={handleOnChange}
      />
      <small id="passwordHelpBlock" className="form-text text-muted">
        <FormattedMessage id="herramientas-logo-placeholder" />
      </small>
    </div>
  );
};

export default LogoForm;

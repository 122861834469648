import React from 'react';

const NotificationSucess = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill={props.color} d="M7.361 11.298L6 12.665 10.345 17 18 9.368 16.639 8l-6.294 6.274z"/>
    </svg>
  )
}

export default NotificationSucess;
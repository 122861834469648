import axios from "axios";
import { devbugData } from "../libs/methods";

export function getMembers(dispatch, filters, token) {
  axios
    .get(
      `${devbugData()}/members/?items=${filters.pagination.items}&page=${
        filters.pagination.page
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      dispatch({ type: "SET_MEMBERS", members: res.data.message });
    })
    .catch((err) => {
      console.log(err);
    });
}
export function deleteMember(token, member_id) {
  return new Promise((response, reject) => {
    axios
      .delete(`${devbugData()}/members/${member_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        response(res.data.message);
      })
      .catch((err) => {
        // Error 😨
        if (err.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          reject(err.response.data);
        } else if (err.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          reject("connection_refused");
          console.log(err.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", err.message);
          reject("network problem");
        }
      });
  });
}

export function getMember(token, member_id) {
  return new Promise((response, reject) => {
    axios
      .get(`${devbugData()}/members/${member_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        response(res.data.message);
      })
      .catch((err) => {
        // Error 😨
        if (err.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          reject(err.response.data);
        } else if (err.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          reject("connection_refused");
          console.log(err.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", err.message);
          reject("network problem");
        }
      });
  });
}
export function updateMember(token, member) {
  return new Promise((response, reject) => {
    axios
      .put(`${devbugData()}/members`, member, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        response(res);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
export function addMember(token, member) {
  return new Promise((response, reject) => {
    axios
      .post(`${devbugData()}/members`, member, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        response(res);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

import React from "react";

const Map = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 64 64"
    >
      <g>
        <path
          fill={props.color}
          d="M44.525,20.734c0-6.919-5.608-12.527-12.528-12.527s-12.527,5.608-12.527,12.527c0,9.913,12.527,24.581,12.527,24.581   S44.525,30.596,44.525,20.734z M25.819,19.986c0-3.413,2.767-6.179,6.179-6.179c3.412,0,6.179,2.766,6.179,6.179   c0,3.413-2.767,6.179-6.179,6.179C28.586,26.165,25.819,23.399,25.819,19.986z"
        />
        <circle fill={props.color} cx="31.997" cy="19.986" r="3.038" />
        <path
          fill={props.color}
          d="M39.08,40.958c-1.021,1.475-1.979,2.761-2.777,3.793c7.916,0.475,13.104,2.184,15.034,3.456   c-2.261,1.491-8.98,3.587-19.338,3.587c-10.358,0-17.077-2.097-19.338-3.587c1.93-1.271,7.114-2.979,15.022-3.455   c-0.8-1.032-1.759-2.317-2.781-3.792c-9.741,0.959-16.816,3.841-16.816,7.246c0,4.19,10.707,7.588,23.913,7.588   c13.207,0,23.912-3.397,23.912-7.588C55.913,44.799,48.83,41.916,39.08,40.958z"
        />
      </g>
    </svg>
  );
};

export default Map;

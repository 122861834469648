import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import translate from "../../../../i18n/translate";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const SridForm = forwardRef((props, ref) => {
  const [error, setError] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(false);
  const [check, setCheck] = useState(props.geoservice === 0 ? false : true);

  useEffect(() => {
    if (props.geoservice !== 0) {
      setCheck(true);
    }
  }, [props.geoservice]);

  //disable check if custom value is set
  useEffect(() => {
    if (props.srid !== "") {
      setCheckDisabled(true);
    } else {
      setCheckDisabled(false);
    }
  }, [props.srid]);

  //display error form parent component
  useImperativeHandle(ref, () => ({
    displayError(what) {
      setError(what);
    },
  }));
  const handleOnChange = (e) => {
    const { value } = e.target;
    if (value.substring(0, 5) !== "EPSG:" && value !== "") {
      setError(true);
      props.setSrid(value);
    } else {
      setError(false);
      props.setSrid(value);
    }
  };

  const handleCheckbox = (e) => {
    if (check) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  };
  return (
    <React.Fragment>
      <div className="form-group">
        <label>
          <FormattedMessage id="vidromaps-srid" />
        </label>
        {error && (
          <div className="alert alert-danger" role="alert">
            <FormattedMessage id="vidromaps-error-srid" />
          </div>
        )}
        {props.geoservice !== 0 && (
          <div>
            <input
              disabled={checkDisabled}
              type="checkbox"
              name="use-project-srid"
              id="use-project-srid"
              checked={check}
              onChange={(e) => handleCheckbox(e)}
            />
            <label className="ml-1">
              {translate("vidromaps-use-project-srid")}
            </label>
          </div>
        )}
        {!check && (
          <React.Fragment>
            <input
              type="text"
              className={classNames("form-control", {
                "is-invalid": error,
              })}
              name="srid"
              placeholder="EPSG:25831"
              value={props.srid}
              onChange={handleOnChange}
            />
            {props.geoservice !== 0 && (
              <small id="passwordHelpBlock" className="form-text text-muted">
                <FormattedMessage id="vidromaps-srid-help" />
              </small>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});
export default SridForm;

import React, { useImperativeHandle, forwardRef, useState } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const NameForm = forwardRef((props, ref) => {
  const [error, setError] = useState(false);

  //display error form parent component
  useImperativeHandle(ref, () => ({
    displayError(what) {
      setError(what);
    },
  }));
  const handleOnChange = (e) => {
    const { value } = e.target;
    if (value.match("^[A-Za-z0-9]+$")) {
      setError(false);
    } else {
      setError(true);
    }
    props.setName(value);
  };
  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="herramientas-banner-input-name" />*
      </label>
      {error && (
        <div className="alert alert-danger" role="alert">
          {props.name === "" && (
            <FormattedMessage id="vidromaps-name-mandatory" />
          )}
          {props.name !== "" && (
            <FormattedMessage id="vidromaps-error-only-letters-and-numbers" />
          )}
        </div>
      )}

      <input
        type="text"
        className={classNames("form-control", {
          "is-invalid": error,
        })}
        name="name"
        value={props.name}
        onChange={handleOnChange}
      />

      <small id="passwordHelpBlock" className="form-text text-muted">
        <FormattedMessage id="vidromaps-name-help" />
      </small>
    </div>
  );
});

export default NameForm;

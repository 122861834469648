import React from "react";
import { FormattedMessage } from "react-intl";
const DescriptionForm = (props) => {
  const handleOnChange = (e) => {
    const { value } = e.target;

    props.setDescription(value);
  };
  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="herramientas-banner-input-description" />
      </label>
      <input
        type="text"
        className="form-control"
        name="description"
        value={props.description}
        onChange={handleOnChange}
      />
      <small id="passwordHelpBlock" className="form-text text-muted">
        <FormattedMessage id="vidromaps-description-help" />
      </small>
    </div>
  );
};

export default DescriptionForm;
